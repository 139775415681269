const RightSidebar = ({ schedules }) => {
  // Get appointments starting after the current date and time
  const getUpcomingAppointments = () => {
    const now = new Date();

    // Filter schedules for future appointments
    const futureSchedules = schedules.filter(
      (schedule) => new Date(schedule.startDate) > now
    );

    // Sort by startDate to get the earliest event
    return futureSchedules.sort(
      (a, b) => new Date(a.startDate) - new Date(b.startDate)
    );
  };

  // Calculate remaining time for the next appointment
  const calculateTimeRemaining = (startDate) => {
    const now = new Date();
    const timeDiff = new Date(startDate) - now;

    if (timeDiff <= 0) return "Time's up!";

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return `${days > 0 ? days + " day(s), " : ""}${hours} hour(s) and ${minutes} minute(s) remaining`;
  };

  const upcomingAppointments = getUpcomingAppointments();
  const nextAppointment = upcomingAppointments[0];

  return (
    <div className="schedule-right-sidebar">
      {/* Next Appointments Section */}
      <div className="right-sidebar-panel activity-information">
        <h6 className="title-right-sidebar">Next Appointment</h6>
        {nextAppointment ? (
          <div>
            <div className="colored-dot"></div>
            <p>
              {nextAppointment.title || "Unnamed Appointment"} on{" "}
              {new Date(nextAppointment.startDate).toLocaleString()} (
              {calculateTimeRemaining(nextAppointment.startDate)})
            </p>
          </div>
        ) : (
          <p>No upcoming appointments</p>
        )}
      </div>

      {/* Alerts Section */}
      <div className="right-sidebar-panel recent-activity">
        <h6 className="title-right-sidebar">Alerts</h6>
        <div>
          <p>Alerts are not set up for now.</p>
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;




// const RightSidebar = (schedules) => {


//   return (
//     <div className="schedule-right-sidebar">
//       <div className="right-sidebar-panel activity-information">
//         <h6 className="title-right-sidebar">Next appointments</h6>
//         <div>
//           <div className="colored-dot"></div>
//           <p>{schedules.length()}</p>
//         </div>
//         <div>
//           <div className="colored-dot"></div>
//           <p>Office Meeting</p>
//         </div>
//         <div>
//           <div className="colored-dot"></div>
//           <p>Weekly Report</p>
//         </div>
//         <div>
//           <div className="colored-dot"></div>
//           <p>Client's Meeting / Dealing</p>
//         </div>
//       </div>
//       <div className="right-sidebar-panel recent-activity">
//         <h6 className="title-right-sidebar">Recent Activity</h6>
//         <div>
//           <div className="image-recent-activity-item">img</div>
//           <div>
//             <p className="title-recent-activity-item">
//               Added new Meeting by Greg
//             </p>
//             <p className="info-activity-item">
//               Offline Meeting with Dan Bvrown at 2 8 June 2023
//             </p>
//             <p className="info-activity-item">Today 09:11 AM</p>
//           </div>
//         </div>
//         <div>
//           <div className="image-recent-activity-item">img</div>
//           <div>
//             <p className="title-recent-activity-item">
//               Added new Listing by Lisa Jones
//             </p>
//             <p className="info-activity-item">New Tokyo Apartment</p>
//             <p className="info-activity-item">Today 19:43 AM</p>
//           </div>
//         </div>
//         <div>
//           <div className="image-recent-activity-item">img</div>
//           <div>
//             <p className="title-recent-activity-item">
//               Edward Moore purchased Tpkyo Apartment
//             </p>
//             <p className="info-activity-item">
//               Purchased Tokyo Apartment for $ 44300
//             </p>
//             <p className="info-activity-item">Today 09:23 AM</p>
//           </div>
//         </div>
//         <div>
//           <div className="image-recent-activity-item">img</div>
//           <div>
//             <p className="title-recent-activity-item">
//               New Meeting schedule by David Garcia
//             </p>
//             <p className="info-activity-item">Office meeting at June 2023</p>
//             <p className="info-activity-item">Today 12:19 AM</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default RightSidebar;
