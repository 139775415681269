import React,{useState,useEffect} from 'react';
// import Logo from "../../assets/images/logo_transparent.png";

import Logo from "../../../../assets/images/logo_transparent.png"; // Tell webpack this JS file uses this image
import "./footer.css";
import {  useNavigate,Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Image from "react-bootstrap/Image";
import styled from "styled-components";
import GooglePlayBtn from "../../../../assets/images/gplay_btn.svg";
import AppStoreBtn from "../../../../assets/images/appstore_btn.svg";
import {Select,MenuItem } from '@mui/material';



const Footer = () => {
  const navigate = useNavigate();
  const { t,i18n } = useTranslation();


    return (
  <StyledFooter className="dtree_footer">
    <FooterInner className="dtree_footer section__padding">
      {/* <div className="dtree_footer-heading">
        <h1 className="gradient__text">Book a demo</h1>
      </div>

      <div className="dtree_footer-btn">
        <p>Request Early Access</p>
      </div> */}

      <FooterColumnsWrapper className="dtree_footer-links">
        <LogoColumn className="dtree_footer-links_logo">
          {/* <img src={gpt3Logo} alt="dtreelogo" />
           */}
          <Link to="/" >
            <Image src={Logo} style={{ width: "2em" }} alt="Logo" />
            <span style={{ marginLeft: '0.5em', fontSize: '1em', color: '#335c6e',  fontWeight: 'bold',fontStyle:'unset' }}>diarytree</span>
          </Link>
          <InfoP>
            Search amazing services around the globe, find one and use its
            services
          </InfoP>
          <EmailP>contact@diarytree.com</EmailP>
          <AddressP>Crechterwoord K12 182 DK Alknjkcb</AddressP>
          <AddressP>085-132567</AddressP>
          <AddressP>info@payme.net</AddressP>
        </LogoColumn>
        <RightSide>
          <div className="dtree_footer-links_div">
            <h4>Links</h4>
            <LinkColumn>Overons</LinkColumn>
            <LinkColumn>Social Media</LinkColumn>
            <LinkColumn>Counters</LinkColumn>
            <LinkColumn>Contact</LinkColumn>
          </div>
          <div className="dtree_footer-links_div">
            <h4>Company</h4>
            <LinkColumn>Terms & Conditions </LinkColumn>
            <LinkColumn>Privacy Policy</LinkColumn>
            <LinkColumn>Contact</LinkColumn>
          </div>
          <div className="dtree_footer-links_div">
            <h4>Download our app</h4>
            <InfoP>Get the best experience by downloading our app from the Play Store or App Store.</InfoP>
            <Separator/><Separator/>
            <AppLink to="/">
            <img src={GooglePlayBtn} alt="diary tree google play" />
            </AppLink>
            <AppLink to="/">
            <Separator />
            <img src={AppStoreBtn} alt="diary tree app store" />
            </AppLink>
          </div>         
        </RightSide>
      </FooterColumnsWrapper>

      <Copyright>
      <p>&copy; {new Date().getFullYear()} diarytree.com. {t('HomePage.Footer_rightReserved')}.</p>
      </Copyright>
    </FooterInner>
  </StyledFooter>
)

};
const Separator = styled.div`
  height: 10px
`;

const AppLink = styled(Link)`
  object-fit: contain;
`;

const FooterColumnsWrapper = styled.div`
  @media (max-width: 768px) {
     flex-direction: column;
  }
`
const StyledFooter = styled.footer`
  background: rgba(244, 249, 255, 0.8);
  padding-top: 50px;
  padding-bottom: 0;
  padding-left: 24px;
  padding-right: 24px;
`;

const FooterInner = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  padding-bottom: 0;
`;

const LogoColumn = styled.div`
  width: 370px;
  padding-right: 100px;
  @media (max-width: 900px) {
      width: 280px;
      padding-right: 30px;
  }
`;
const RightSide = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  @media (max-width: 768px) {
      padding-top: 30px;
      flex-direction: column;
      h4 {
        padding-top: 30px;
      }
  }
`;
const LinkColumn = styled.div`
  font-size: 0.9rem;
  line-height: 2rem;
`;
const InfoP = styled.p`
  font-size: 13px;
  line-height: 22px;
`;
const EmailP = styled.p`
  font-size: 20px;
  margin-top: 20px;
  color: #111 !important;
`;

const AddressP = styled.p`
  font-size: 0.9rem;
  color: #5e5e5e;
`;

const Copyright = styled.div`
  margin-top: 40px;
  padding: 25px 0;
  border-top: 1px solid #ccc;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export default Footer;

{/* <Heading>Social Media</Heading>
<FooterLink href="#">
<i className="fab fa-facebook-f">
  <span style={{ marginLeft: "10px" }}>
  Facebook
  </span>
</i>
</FooterLink>
<FooterLink href="#">
<i className="fab fa-instagram">
  <span style={{ marginLeft: "10px" }}>
  Instagram
  </span>
</i>
</FooterLink>
<FooterLink href="#">
<i className="fab fa-twitter">
  <span style={{ marginLeft: "10px" }}>
  Twitter
  </span>
</i>
</FooterLink>
<FooterLink href="#">
<i className="fab fa-youtube">
  <span style={{ marginLeft: "10px" }}>
  Youtube
  </span>
</i>
</FooterLink> */}

// import React,{useState,useEffect} from 'react';
//  import Logo from '../../assets/images/logo_transparent.png';
//  import { useNavigate, Link } from 'react-router-dom';
// import './footer.css';
// import Image from "react-bootstrap/Image";
// import { useTranslation } from 'react-i18next';
// import {Select,MenuItem } from '@mui/material';


// const Footer = () => {
//   const navigate = useNavigate();
//   const { t,i18n } = useTranslation();
// 	const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

//     const changeLanguage = (lng) => {
//       i18n.changeLanguage(lng);
//       setSelectedLanguage(lng);    
//       localStorage.setItem('selectedLanguage', lng);
//     };



//     const handleRegisterClick = () => {
//       navigate('/register');
//     };
  

//     useEffect(() => {
//       setSelectedLanguage(i18n.language);
//     }, [i18n.language]);


// return (
// <footer className='dtree_footer'>
//     <div className="dtree_footer section__padding">
//       <div className="dtree_footer-links">
//         <div className="dtree_footer-links_logo">
//         <Link to="/" style={{ width: "5em" }}>
//         <Image src={Logo} alt="Logo" />
//       </Link>
//           <p>{t('HomePage.dashboardHeaderPresentation')}</p>
//         </div>
//         <div className="dtree_footer-links_div">
//           <h4>{t('HomePage.Footer_site')}</h4>
//           <p>{t('HomePage.Footer_prices')}</p>
//           <p>{t('HomePage.Footer_contact')}</p>
//           <Link to="/login"><p>{t('HomePage.Common_login')}</p></Link>
//           <Link to="/register"><p>{t('HomePage.Common_register')}</p></Link>
//         </div>
//         <div className="dtree_footer-links_div">
//           <h4>{t('HomePage.Footer_support')}</h4>
//           <p>{t('HomePage.Footer_cgv')}</p>
//           <p>{t('HomePage.Footer_cgu')}</p>
//           <p>{t('HomePage.Footer_cookies')}</p>
//         </div>      
//         <div className="dtree_footer-links_div">
//           <h4>{t('HomePage.Common_language')}</h4>
//              <Select
//                 labelId="language-select" className='language-select'
//                 id="language-select"
//                 label={t('HomePage.Common_language')}
//                 value={selectedLanguage}
//                 onChange={(e) => changeLanguage(e.target.value)}
//               >
//                 <MenuItem value="en">English</MenuItem>
//                 <MenuItem value="fr">Français</MenuItem>
//                 <MenuItem value="de">German</MenuItem>
//                 <MenuItem value="ar">Arabic</MenuItem>
//               </Select>
//         </div>
//       </div> 

//       <hr className="footer-hr" />
//         <div className="footer-bottom">
//           <p>&copy; {new Date().getFullYear()} diarytree.com. {t('HomePage.Footer_rightReserved')}.</p>
//         </div>
//     </div>
//   </footer>
// );
// }

// export default Footer;