import React, { useEffect , useRef} from 'react';
import { Route, Routes, Navigate,useLocation  } from 'react-router-dom';
import { Box } from '@mui/material';
 import Schedule from './Components/CalendarPageNew';
import { AdminPage } from './pages/AdminPage';
import { LoginPage } from './pages/LoginPage';
import { ProfessionalCreationPage } from './pages/ProfessionalCreationPage';

import { RegisterPage } from './pages/RegisterPage';
import { EmailConfirmationPage } from './pages/EmailConfirmationPage';
import { SharedLayout } from './pages/SharedLayout';

import { UserProfilePage } from './pages/UserProfilePage';
import './App.css';
import Home from './pages/Home';
import ProfessionalAvailability from './pages/ProfessionalAvailability';
import "@fontsource/architects-daughter";
import {useDispatch, useSelector} from "react-redux";
import { setToken,checkToken } from './app/services/user.slice';
import UserDashboard from './pages/UserDashboardPage/UserDashboard';
import Theme from "./helpers/theme";

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Your i18n configuration file
// import AOS from 'aos';
// import 'aos/dist/aos.css';



const App = () => {
  const user = useSelector(state=>state.user);
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const location = useLocation();

  // useEffect(() => {
  //   // Access the current root element
  //   const rootElement = rootRef.current;
  // 
  //   console.log(rootElement); // This will log the current root element to the console
  // }, []);

  useEffect(()=>{
      if(user.token && !user.authenticated){
        // 
        dispatch(checkToken());
        return;
      }
  },[user,dispatch]);


  return (
    
    <Theme>
    <I18nextProvider i18n={i18n}>
        <Box style={{height:'100hv'}} >
            <Routes>
              <Route exact path="/" element={<SharedLayout />} >
                    <Route path="/confirm-email/:userId/:token" element= {(<EmailConfirmationPage />) } />
                    <Route path="login" state={{ from: location }}  element= {!user.authenticated ? ( <LoginPage /> ) : ( <Navigate to="/schedule" replace />)}/>
                    <Route path="register" element={!user.authenticated ? ( <RegisterPage /> ) : ( <Navigate to="/schedule" replace />)} />
                    <Route path="professionalcreation" element={ <ProfessionalCreationPage /> } />
                    <Route index element={<Home />} />
                    <Route path="/availability/:params" element={<ProfessionalAvailability />} />
                    {/* <Route path="dashboard" element={user.authenticated ? ( <UserDashboard /> ) : ( <Navigate to="/login" replace />)} /> */}
                    <Route path="schedule" element={user.authenticated ? ( <Schedule /> ) : ( <Navigate to="/login" replace state={{ from: location }} />)} />
                    <Route path="admin" element={user.authenticated ? ( <AdminPage /> ) : ( <Navigate to="/login" replace state={{ from: location }} />)} />
                    <Route path="Profile" element={user.authenticated ? ( <UserProfilePage  /> ) : ( <Navigate to="/login" replace state={{ from: location }} />)} />
                    
              </Route>
            </Routes>
      </Box>
    </I18nextProvider>
    </Theme>
  );


}

export default App;

// 