import { useState, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useMatch,useNavigate, useResolvedPath,useLocation  } from "react-router-dom";
import image from "../../assets/images/logo_transparent.png"; // Tell webpack this JS file uses this image
import { MessageOutlined, BellOutlined } from "@ant-design/icons";
import { authenticationService } from "../../app/services";
import ProfileSubMenu from "./ProfileSubMenu";
import Image from "react-bootstrap/Image";
import NotificationSubMenu from "./NotificationSubMenu";
import SignalRService from "../../app/services/signalRService";
import {Select,MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

// import CustomLink from "../../Components/CustomLink/CustomLink";
import "./navbar.css";

 const Navbar = (props) => {
    const { t,i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      setSelectedLanguage(lng);    
      localStorage.setItem('selectedLanguage', lng);
    };
    useEffect(() => {
          if (i18n.language) { // Ensure i18n.language is defined
            const languageShort = i18n.language.includes('-')
              ? i18n.language.split('-')[0] // First character before the dash
              : i18n.language[0]; // First character if no dash
            setSelectedLanguage(languageShort); // Update the state with the short language code
          }
        }, [i18n.language]);
   const [notifications, setNotifications] = useState([]);
   const location = useLocation();
//     {
//       id: 1,
//       title: "New Message",
//       message: "You have a new message from John.",
//     },
//     {
//       id: 2,
//       title: "Friend Request",
//       message: "You received a friend request from Alice.",
//     },
//     // Add more notifications as needed
//   ]);

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if(authenticationService.currentUserToken!= null)
    {
    // const signalRService = new SignalRService();

    const signalRService = SignalRService.getInstance();
    console.log("navbar use effect signalr");

    signalRService
      .startConnection()
      .then(() => {
        console.log("SignalR connection established.");
        // Subscribe to events
        signalRService.onNotificationReceived(handleNotificationReceived);
      })
      .catch((error) => {
        console.error("Error establishing SignalR connection:", error);
      });

    return () => {
      // Unsubscribe from events and stop the connection
      signalRService.off("NotificationReceived", handleNotificationReceived);
      signalRService.stopConnection();
    };   
  }
  }, []);

  // useEffect(() => {
  //       debugger;
  // }, [t]);
  
  const handleNotificationReceived = (notification) => {
    setNotifications((prevNotifications) => [
      ...prevNotifications,
      notification,
    ]);
    setUnreadCount((prevUnreadCount) => prevUnreadCount + 1);
  };

  // Function to delete a notification
  const deleteNotification = (notificationToDelete) => {
    const updatedNotifications = notifications.filter(notification => notification.id !== notificationToDelete.id);
    setNotifications(updatedNotifications);
  };


  // height: "80px",
  return (
    <nav
      className="nav"
      style={{
        alignItems: "center",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        backgroundColor: props.backgroundColor,
        display: "flex",
        flexDirection: "row", // Horizontal layout
        justifyContent: "space-between", // Align logo and menu button on opposite sides
        padding: "0 1em", // Add padding for spacing
      }}
    >
      <Link to="/" className="site-title">
        <Image src={image} style={{ width: "2em" }} alt="Logo" />
        <span style={{ marginLeft: '0.5em', fontSize: '1em', color: '#335c6e',  fontWeight: 'bold',fontStyle:'unset' }}>diarytree</span>
      </Link>

      <ul className={`menu-nav ${menuOpen ? "open" : "full-menu"}`}>
        {authenticationService.currentUserToken == null && (
          <CustomLink

            to={{
              pathname: "/login",
              state: { from: location.pathname },
            }}
            className={`nav-item nav-link ${!menuOpen ? "rounded-button" : ""}`}
          >
            {t("HomePage.Navbar_login")}
          </CustomLink>
        )}
        {authenticationService.currentUserToken == null && (
          <CustomLink
            to={{
              pathname: "/professionalcreation",
              state: { from: location.pathname },
            }}
            className={`nav-item nav-link ${
              !menuOpen ? "rounded-button filled-button" : ""
            }`}
          >
            {t('HomePage.Navbar_Pro')}
          </CustomLink>
        )}
        {authenticationService.currentUserToken != null && (
          <CustomLink
            to="/"
            className="nav-item nav-link top-nav-link"
          >
            
            {t('HomePage.Navbar_Home')}
          </CustomLink>
        )}
        {authenticationService.currentUserToken != null && (
          <CustomLink to="/schedule" className="nav-item nav-link top-nav-link">
            {t('HomePage.Navbar_Schedule')}
          </CustomLink>
        )}
        {authenticationService.currentUserToken != null && (
          <CustomLink to="/admin" className="nav-item nav-link top-nav-link">       
           {t('HomePage.Navbar_Admin')}
          </CustomLink>
        )}{" "}
        {/* //&& isAdmin */}
        {authenticationService.currentUserToken != null && (
          <NotificationSubMenu notifications={notifications}  deleteNotification={deleteNotification} />
        )}
        {authenticationService.currentUserToken != null && (
          <ProfileSubMenu appendClass="desktop" />
        )}

        <li>
        <div className="dtree_footer-links_div">
            <Select
              labelId="language-select"
              className="language-select"
              id="language-select"
              value={selectedLanguage}
              onChange={(e) => changeLanguage(e.target.value)}
            >
              <MenuItem value="en">EN</MenuItem>
              <MenuItem value="fr">FR</MenuItem>
              <MenuItem value="de">DE</MenuItem>
              <MenuItem value="ar">AR</MenuItem>
            </Select>
          </div>
        </li>
      </ul>

      {/* Add more menu items for the full menu here */}
      <div className="nav-right-side-mobile">
        {authenticationService.currentUserToken != null && (
          <ProfileSubMenu appendClass="mobile" />
        )}
        <button
          className={`menu-button ${menuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          {/* Use an icon like the hamburger menu icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#664de4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>
    </nav>
  );
};


function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(state => state.user); // Adjust based on your state shape

  const handleClick = (e) => {
    if (!user.authenticated) {
      e.preventDefault();
      navigate(to.pathname, { state: { from: location.pathname } });
    }
  };

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props} onClick={handleClick}>
        {children}
      </Link>
    </li>
  );
}

// HomePage.Navbar_Profile
// HomePage.Navbar_Logout

// function CustomLink({ to, children, ...props }) {
//   const resolvedPath = useResolvedPath(to);
//   const isActive = useMatch({ path: resolvedPath.pathname, end: true });
//   debugger;

//   return (
//     <li className={isActive ? "active" : ""}>
//       <Link to={to} {...props}>
//         {children}
//       </Link>
//     </li>
//   );
// }

export default Navbar;
{/* <div className="dtree_footer-links_div">
<Select
   labelId="language-select" className='language-select'
   id="language-select"
   label={t('HomePage.Common_language')}
   value={selectedLanguage}
   onChange={(e) => changeLanguage(e.target.value)}
 >
   <MenuItem value="en">English</MenuItem>
   <MenuItem value="fr">Français</MenuItem>
   <MenuItem value="de">German</MenuItem>
   <MenuItem value="ar">Arabic</MenuItem>
 </Select>
</div> */}