import React from "react";
import { useTranslation } from 'react-i18next';
import {useNavigate } from 'react-router-dom';
import gifImage from "../../assets/images/diarytree-gif-reduit.gif"; // Add your GIF image path here
import "../../assets/styles/sectionstyle.css";
import styled from "styled-components";
import CustomLink from "../../../../Components/CustomLink/CustomLink";

const Header = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleClick = () => {
      navigate('/register');
    };
  
  
    return (
      <StyledHeaderWrapper className="dtree_header section__padding_side" id="home">
    <div className="dtree_header-main">
      <div className="dtree_header-content main-width">
        <div className="dtree_header-text">
          <span className="dtree_main_header">
            {t("HomePage.dashboardHeaderPresentation")}
            <br />
            <span style={{ color: "#664de4" }}>
              {t("HomePage.dashboardHeaderPresentationColored")}
            </span>
          </span>

          <h2>
            {t("HomePage.dashboardHeaderDetailsFirstPhrase")}
            <br />
            {t("HomePage.dashboardHeaderDetailsSecondPhraseFirstElement")}
            <span style={{ color: "#664de4" }}>
              {t("HomePage.dashboardHeaderDetailsSecondPhraseSecondElement")}
            </span>
            {t("HomePage.dashboardHeaderDetailsSecondPhraseThirdElement")}
          </h2>
        </div>
      </div>
     <ButtonContainer>
            <StyledCTAButton className="nav-item nav-link rounded-button filled-button action-button ">{t("HomePage.Navbar_findProfessional")}</StyledCTAButton>
            <CustomLink className="nav-item nav-link rounded-button filled-button action-button ">{t("HomePage.Navbar_joinProfessional")}</CustomLink>
       </ButtonContainer>
    </div>  

    <StyledGifWrapper className="dtree_header-gif">
      <img src={gifImage} alt="GIF" />
    </StyledGifWrapper>
  </StyledHeaderWrapper>
) 
};

const StyledGifWrapper = styled.div`
  padding: 40px 0;
`

const StyledHeaderWrapper = styled.div`
   @media (max-width:1024px) {
      flex-direction: column-reverse;
      height: auto !important;
  } 
  @media (max-width:768px) {
      padding: 100px 0;
  } 
  @media (max-width:640px) {
      padding-top: 0;
  } 
`;

const StyledH3 = styled.h3`
  font-size: 2.8rem;
  text-align: left;
  @media (max-width:1024px) {
    text-align: center;
  }
  @media (max-width:1024px) {
    font-size: 2rem;
  }
`;

const InfoP = styled.p`
  text-align: left;
  line-height: 2.3rem !important;
  @media (max-width:1024px) {
    text-align: center;
  }
  @media (max-width:1024px) {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    padding-left: 15px;
    padding-right: 15px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between; /* Adjust space between buttons */
  align-items: center;
  gap: 10px; /* Optional: space between buttons */
  width: 100%; /* Or adjust as needed */
  @media (max-width: 640px) {
    flex-wrap: wrap; /* Stack buttons on smaller screens */
    justify-content: center;
  }
`;
const StyledCTAButton = styled.button`
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 0;
  height: 54px;
  width: 370px;
  font-size: 1.2rem;
  font-weight: 500;
  color: White; 
  background-color: #664de4!important
  text-decoration: none; 
  align-items: center;
  border: 2px solid #664de4;
  transition: all 0.3s ease-in-out;
  @media (max-width:1024px) {
    margin: 0 auto;
  }
  &:hover {
      opacity: 0.9;
  }
  @media (max-width:640px) {
    width: 320px;
  }
`;

export default Header;


  // <StyledHeaderWrapper className="dtree_header section__padding_side" id="home">
  //   <div className="dtree_header-main">
  //     <div className="dtree_header-content main-width">
  //       <div className="dtree_header-text">
  //         <StyledH3 className="archivo-bold">
  //           {t("HomePage.dashboardHeaderPresentation")} <br />
  //           <span style={{ color: "#664de4" }}>
  //             {t("HomePage.dashboardHeaderPresentationColored")}
  //           </span>
  //         </StyledH3>
  //         <InfoP>
  //           {t("HomePage.dashboardHeaderDetailsFirstPhrase")} <br />
  //           {t("HomePage.dashboardHeaderDetailsSecondPhraseFirstElement")}
  //           <span style={{ color: "#664de4" }}>
  //             {" "}{t("HomePage.dashboardHeaderDetailsSecondPhraseSecondElement")}{" "}
  //           </span>
  //           {t("HomePage.dashboardHeaderDetailsSecondPhraseThirdElement")}
  //         </InfoP>
  //       </div>
  //     </div>

  // <ButtonContainer>
  //     <StyledCTAButton>{t("HomePage.Navbar_findProfessional")}</StyledCTAButton>
  //     <StyledCTAButton>{t("HomePage.HomePage.Navbar_joinProfessional")}</StyledCTAButton>
  //     </ButtonContainer>
  //   </div>



// import React from "react";
// import {useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';


// import people from "../../assets/images/people.png";
// import gifImage from "../../assets/images/diarytree-gif-reduit.gif"; // Add your GIF image path here
// import "../../assets/styles/sectionstyle.css";

// const Header = () => {

//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const handleClick = () => {
//     navigate('/register');
//   };


//   return (



//   <div className="dtree_header section__padding_side" id="home">
//     <div className="dtree_header-main">
//       <div className="dtree_header-content main-width">
//         <div className="dtree_header-text">
//           <span className="dtree_main_header">
//             {t("HomePage.dashboardHeaderPresentation")}
//             <br />
//             <span style={{ color: "#664de4" }}>
//               {t("HomePage.dashboardHeaderPresentationColored")}
//             </span>
//           </span>

//           <h2>
//             {t("HomePage.dashboardHeaderDetailsFirstPhrase")}
//             <br />
//             {t("HomePage.dashboardHeaderDetailsSecondPhraseFirstElement")}
//             <span style={{ color: "#664de4" }}>
//               {t("HomePage.dashboardHeaderDetailsSecondPhraseSecondElement")}
//             </span>
//             {t("HomePage.dashboardHeaderDetailsSecondPhraseThirdElement")}
//           </h2>
//         </div>
//       </div>
      
//       <button className="headerbutton" onClick={handleClick}>
//         {t('HomePage.dashboardHeaderGetStartedButton')}
//       </button>
//     </div>

//     <div className="dtree_header-gif">
//       <img src={gifImage} alt="GIF" />
//     </div>
//   </div>
// )
// };


// export default Header;
