import { Box, Container, Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";

import FeatImage01 from "../../../assets/images/dashboard-hairdresserbooking-fotor-bg-remover-20230522222429.png";
import CustomLink from "../../../Components/CustomLink/CustomLink";

const CTASection = () => {
  return (
    <div className="dtree_section_header">
      <Container>
        <Box
          sx={{
            backgroundColor: "rgba(244, 249,255, .8)",
            borderRadius: "20px",
            padding: "0 40px",
            paddingTop: {
              xs: "40px",
              md: 0
            },
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row"
            },
          }}
        >
          <Box
            sx={{
              flex: 1,
              textAlign: "left",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Box
              sx={{
                textAlign: {
                  xs: "center",
                  md: "left"
                },
                textTransform: "uppercase",
                color: "#5d5dff",
              }}
              className="poppins-medium"
            >
              Reach goals that matter TOTO
            </Box>
            <StyledH3 className="archivo-bold">
              One product, unlimited solutions
            </StyledH3>
            <StyledP>
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit laborum — semper quis lectus nulla.
            </StyledP>
            <CustomLink  className="nav-item nav-link rounded-button filled-button action-button">Get started</CustomLink>
            
          </Box>
          <Grid item xs={12} md={6} sx={{ paddingBottom: { xs: '0', md: '100px' } }} className="dtree_section_element-image">
            <img
              src={FeatImage01}
              data-aos="fade-up"
              style={{ objectFit: "contain" }}
            />
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

const StyledCTAButton = styled.button`
  border-radius: 5px;
  margin-top: 60px;
  padding: 10px 0;
  height: 54px;
  font-size: 1.2rem;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  @media (max-width:900px) {
    margin: 0 auto;
  }
  &:hover {
      opacity: 0.9;
  }
`;

const StyledP = styled.p`
  font-size: 1rem;
  @media (max-width: 900px) {
      text-align: center;
  }
`;

const StyledH3 = styled.h3`
  font-size: 2.8rem;
  @media (max-width:900px) {
    text-align: center;
    padding-bottom: 20px;
  }
  @media (max-width:1024px) {
    font-size: 2rem;
  }
`;

export default CTASection;
