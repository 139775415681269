import React from "react";
import { Link, useMatch, useResolvedPath, useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";


const CustomLink = ({ to, children, className = "", isSubmit, formId, ...props }) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  const handleClick = (e) => {
    if (isSubmit) {
      e.preventDefault(); // Prevent default link behavior
      const form = document.getElementById(formId);
      if (form) {
        form.submit(); // Manually submit the form
      }
    }
  };

  return (
    <li className={`${className} ${isActive ? "active" : ""}`}>
      <Link to={to || "#"} {...props} onClick={handleClick}>
        {children}
      </Link>
    </li>
  );
};




// const CustomLink = ({ to, children, className = "", onClick, requireAuth, fallbackPath, ...props }) => {
//   const resolvedPath = useResolvedPath(to);
//   const isActive = useMatch({ path: resolvedPath.pathname, end: true });
//   const navigate = useNavigate();
//   const location = useLocation();

//   const handleClick = (e) => {
//     if (requireAuth) {
//       e.preventDefault();
//       navigate(fallbackPath || "/login", { state: { from: location.pathname } });
//     } else if (onClick) {
//       onClick(e);
//     }
//   };

//   return (
//     <li className={`${className} ${isActive ? "active" : ""}`}>
//       <Link to={to} {...props} onClick={handleClick}>
//         {children}
//       </Link>
//     </li>
//   );
// };

CustomLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  requireAuth: PropTypes.bool, // If true, redirects to fallbackPath when unauthenticated
  fallbackPath: PropTypes.string, // Path to redirect if requireAuth is true and unauthenticated
};




export default CustomLink;
