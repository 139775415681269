import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { Badge, Avatar, Divider, Select, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField, ListItemAvatar, Button, CircularProgress, Box } from '@mui/material';

import ServiceSelector from '../../Components/CalendarPageNew/Component/ServiceSelector';
import TimeSlotList from '../../Components/CalendarPageNew/Component/TimeSlotList';
import { LocalizationProvider, PickersDay, DateCalendar } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import * as images from "../../assets/images";

import { scheduleService, companyService } from "../../app/services";
import { useDispatch, useSelector } from "react-redux";
import zIndex from '@mui/material/styles/zIndex';
import { authenticationService} from "../../app/services";

const getImageForBusiness = (businessType) => {
  // Avoid recursion if businessType is "defaultImage"
  const normalizedBusinessType = businessType.toLowerCase();
  if (businessType === "defaultImage") {
    return images.defaultImage; // Directly return the default image
  }
  // Fallback image in case the key is invalid
  const fallbackImage = images.defaultImage;

  // Access the image dynamically with a fallback
  return images[normalizedBusinessType] || fallbackImage;
};


const ProfessionalAvailability = () => {
  const user = useSelector(state => state.user);
  const { params } = useParams();
  const [source, professionalId] = params.includes('&') ? params.split('&') : ['defaultSource', params];
  const initialValue = new Date();
  const [availability, setAvailability] = useState([]);
  const [professional, setProfessional] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedDate, setSelectedDate] = useState(initialValue);
  const [services, setServices] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({ id: '' });
  const [selectedServices, setSelectedServices] = useState([]);
  const [totalDuration, setTotalDuration] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [highlightedDays, setHighlightedDays] = useState([1, 2, 15]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState("");



  const [isUserConnected, setIsUserConnected] = useState(false);
  const [loginOption, setLoginOption] = useState(null);
  const [loginPopupOpen, setLoginPopupOpen] = useState(false);
  const [manualFormOpen, setManualFormOpen] = useState(false);
  const [credentials, setCredentials] = useState({ email: '', password: '' });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      id: "",
      companyid: "",
      companyemployeeid: "",
      title: "",
      startDate: null,
      endDate: null,
      calendarid: "1",
      description: "",
      companyemployeeservices: selectedServices,
    },
  });
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    phoneNumber: ''
  });


  useEffect(() => {
    if (user.authenticated) {
      setIsUserConnected(true);
      setUserDetails({
        name: user.user.sub,
        email: user.user.email,
        phoneNumber: ''
      });
    }
  }, [user]);

  useEffect(() => {
    companyService.getCompaniesByCategoryAndLocationAndCompanyidNotConnected(null, null, professionalId)
      .then((company) => {
        setProfessional(company[0]);
        setBackgroundImage(getImageForBusiness(company[0].category));
      })
      .catch((error) => {
        console.error("Failed to fetch professionals:", error);
        setError(error);
      });
  }, [professionalId]);

  useEffect(() => {
    if (professional) {
      companyService.getCompanyEmployeesNotConnected(professional.username)
        .then(response => {
          const anyEmployee = { id: '0', name: 'Any Employee', role: '', image: '' };
          setEmployees([anyEmployee, ...response.data]);

          setSelectedEmployee(
            { id: '0', name: 'Any Employee', role: '', image: '' }
          );
        })
        .catch(error => {
          console.error('Failed to fetch employees:', error);
          setError(error);
        });


    }
  }, [professional]);

  useEffect(() => {
    if (selectedEmployee && selectedEmployee.id != "" && selectedEmployee.id != "0") {
      companyService.getCompanyServicesByEmployeeNotConnected(professional.username, selectedEmployee.username)
        .then(response => {
          setServices(response);
          setValue('companyemployeeid', selectedEmployee.username);
        })
        .catch(error => {
          console.error('Failed to fetch services:', error);
          setError(error);
        });
    }
    else {
      if (professional && professional.username != null) {
        companyService.getCompanyServicesByEmployeeNotConnected(professional.username, "")
          .then(response => {
            setServices(response);
          })
          .catch(error => {
            console.error('Failed to fetch services:', error);
            setError(error);
          });
      }

    }
  }, [selectedEmployee, setValue]);

  useEffect(() => {
    if (selectedEmployee && selectedServices.length > 0) {
      const totalDuration = calculateTotalDuration();
      const totalPrice = calculateTotalPrice();
      setTotalDuration(totalDuration);
      setTotalPrice(totalPrice);

      companyService.getCompanyDisponibilitiesNotConnected(professional, selectedEmployee, selectedDate, 1, totalDuration)
        .then((availabilities) => {
          setTimeSlots(availabilities);
        })
        .catch((error) => {
          console.error('Failed to fetch time slots:', error);
          setError(error);
        });
    }
  }, [selectedDate, selectedServices, selectedEmployee, professional]);


  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleLogin = () => {
    // Simulate login logic
    setLoading(true);
     authenticationService
    .login(credentials.email, credentials.email, credentials.password, "")
    .then(
      (user) => {
              // Ensure returnUrl is valid, otherwise, redirect to a default route
        // const destination = returnUrl?.pathname || '/';
        // console.log(destination);
        // navigate(destination);
        setLoading(false);
        window.location.reload(); // Refresh the page
      },
      (error) => {

        setErrorMessage(error.message);
      }
    );

    setTimeout(() => {
      setIsUserConnected(true); // Assume login is successful
    }, 1500); // Simulate a delay for login
  };

  // Handle manual form submission
  const handleManualFormSubmit = () => {
    if (userDetails.name && userDetails.email && userDetails.phoneNumber) {
      setIsUserConnected(true);
      setManualFormOpen(false);
    }
  };



  const calculateTotalDuration = () => {
    return selectedServices.reduce((total, service) => total + parseInt(service.duration, 10), 0);
  };

  const calculateTotalPrice = () => {
    return selectedServices.reduce((total, service) => total + parseInt(service.price, 10), 0);
  };

  const ServerDay = ({ day, outsideCurrentMonth, highlightedDays, ...other }) => {
    const isSelected = !outsideCurrentMonth;
    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        badgeContent={isSelected ? '🌚' : undefined}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <PickersDay
          {...other} // Ensure this does not include invalid DOM props
          day={day}
          outsideCurrentMonth={outsideCurrentMonth}
        />
      </Badge>
    );
  };
  // const ServerDay = (props) => {
  //   const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
  //   const isSelected = !outsideCurrentMonth;
  //   return (
  //     <Badge
  //       key={day.toString()}
  //       overlap="circular"
  //       badgeContent={isSelected ? '🌚' : undefined}
  //       anchorOrigin={{
  //         vertical: 'top',
  //         horizontal: 'right',
  //       }}
  //     >
  //       <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
  //     </Badge>
  //   );
  // };

  const handleEmployeeSelect = (event) => {
    const employeeId = event.target.value;
    const employee = employees.find(emp => emp.id === employeeId);
    setSelectedEmployee(employee);
  };

  const handleServiceSelect = (service) => {
    setSelectedServices((prevSelectedServices) => {
      if (prevSelectedServices.includes(service)) {
        return prevSelectedServices.filter((s) => s.id !== service.id);
      } else {
        return [...prevSelectedServices, service];
      }
    });
    // Update the value of the field with the new JSON string
    // setValue('companyemployeeservices', selectedServices);
  };

  const handleSlotSelect = (slot) => {
    setSelectedSlot(slot);


    setValue('companyemployeeid', slot.companyemployeeid);
    setValue('companyid', slot.companyid);
    setValue('title', slot.title);
    setValue('description', slot.description);
  };

  const handleMonthChange = (date) => {
    setHighlightedDays([]);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const shouldDisableDate = (date) => {
    return date < new Date();
  };

  const handleFormSubmit = (data) => {
    debugger;
    if (!isUserConnected) {
      alert("Please provide your details");
    } else {
      const submissionData = {
        ...data,
        userDetails: {
          name: userDetails.name,
          email: userDetails.email,
          phoneNumber: userDetails.phoneNumber,
        },
        startDate: selectedSlot ? selectedSlot.startDate : null,
        endDate: selectedSlot ? selectedSlot.endDate : null,
        companyemployeeservices: selectedServices
      };

      console.log("Booking data:", submissionData);
      var result = scheduleService.send(submissionData);
      if (result.succeeded) {
        alert('Submission was successful!');
      }
      else {
        alert('An error occurred during submission.');
      }
      // Proceed with the booking submission (e.g., send to API)
    }
  };

  const handleBooking = async () => {
    if (!user) {
      // Ask the user to log in
      const isLoggedIn = await onLogin(); // Example: This function should handle the login process
      if (!isLoggedIn) {
        alert('Please log in to proceed with booking.');
        return;
      }
    }

    // Proceed with booking logic
    alert('Booking confirmed!'); // Replace with actual booking submission logic
  };

  const renderLoginForm = () => (
    <Box>
      <Button
        variant="contained"
        onClick={() => setLoginOption('google')}
        style={{
          margin: '10px 0',
          backgroundColor: '#4285F4',
          color: '#fff',
          padding: '10px 16px',
          fontSize: '16px',
          fontWeight: 'bold',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'all 0.3s ease-in-out',
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.backgroundColor = '#3367d6';
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.backgroundColor = '#4285F4';
        }}
      >
        Log in with Google
      </Button>
      <Button
        variant="outlined"
        onClick={() => setLoginOption('form')}
        style={{
          margin: '10px',
          border: '2px solid #664de4',
          color: '#664de4',
          padding: '10px 16px',
          fontSize: '16px',
          fontWeight: 'bold',
          borderRadius: '8px',
          transition: 'all 0.3s ease-in-out',
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.color = '#fff';
          e.currentTarget.style.backgroundColor = '#664de4';
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.color = '#664de4';
          e.currentTarget.style.backgroundColor = 'transparent';
        }}
      >
        Log in with Details
      </Button>
    </Box>
  );

  const renderGoogleLogin = () => (
    <Box>
      <Typography variant="h6">Log in with Google</Typography>
      <GoogleLogin
        clientId="YOUR_GOOGLE_CLIENT_ID"
        buttonText="Log in with Google"
        onSuccess={handleGoogleLoginSuccess}
        onFailure={handleGoogleLoginFailure}
        cookiePolicy={'single_host_origin'}
      />
    </Box>
  );

  const renderManualLoginForm = () => (
    <Box>
      <Typography variant="h6" style={{ marginBottom: '12px' }}>
        Your Details
      </Typography>
      <Grid container spacing={1}>
        {/* First Row: Name and Email */}
        <Grid item xs={12} sm={6}>
          <Controller
            name="userName"
            control={control}
            render={({ field }) => (
              <TextField
                label="Name"
                fullWidth
                {...field}
                value={userDetails.name}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, name: e.target.value })
                }
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="userEmail"
            control={control}
            render={({ field }) => (
              <TextField
                label="Email"
                fullWidth
                {...field}
                value={userDetails.email}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, email: e.target.value })
                }
                margin="normal"
              />
            )}
          />
        </Grid>

        {/* Second Row: Phone Number and Submit Button */}
        <Grid item xs={12} sm={6}>
          <Controller
            name="userPhoneNumber"
            control={control}
            render={({ field }) => (
              <TextField
                label="Phone Number"
                fullWidth
                {...field}
                value={userDetails.phoneNumber}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    phoneNumber: e.target.value,
                  })
                }
                margin="normal"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            fullWidth
            style={{
              marginTop: '16px',
              backgroundColor: '#664de4',
              color: '#fff',
              padding: '10px',
              fontWeight: 'bold',
            }}
          >
            Book Now
          </Button>
        </Grid>
      </Grid>
    </Box>

  );

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading data</Typography>;

  return (
    <div>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container spacing={2} justifyContent="center" style={{ backgroundColor: '#f0f4f9' }}>
          {/* Header Section */}
          <Grid item xs={12} style={{ zIndex: '99' }}>
            <Box
              style={{
                position: 'relative',
                display: 'flex', // Flexbox to align logo and text side by side
                justifyContent: 'center', // Center horizontally
                alignItems: 'center', // Center vertically
                textAlign: 'center', // Center text
                color: 'white',
                height: '30vh', // Full viewport height to center vertically
                marginBottom: '4rem',
                borderRadius: '10px', // Adding border radius for rounded corners
                overflow: 'hidden', // Ensuring the image doesn't spill over rounded corners
                padding: '1rem', // Add some padding to avoid content touching edges
              }}
            >
              {/* Background Image */}
              <Box
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${backgroundImage})`, // Use static image
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  filter: 'blur(4px)', // Apply blur effect
                  zIndex: -1,
                  opacity: 0.7, // Adjust transparency
                }}
              />

              {/* Content Section: Logo and Text */}
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center', // Vertically center the content inside this Box
                  justifyContent: 'center', // Center the content horizontally
                  textAlign: 'center',
                }}
              >
                {/* Left Section: Logo */}
                {professional.logo ? (
                  <img
                    src={`data:image/png;base64,${professional.logo}`}
                    alt={`${professional.Name} Logo`}
                    style={{
                      width: '100px',
                      height: '100px',
                      marginRight: '1rem', // Space between the logo and text
                    }}
                  />
                ) : (
                  <Avatar
                    src={professional.logo}
                    alt={professional.name}
                    style={{
                      width: 80,
                      height: 80,
                      marginRight: '1rem', // Space between the logo and text
                    }}
                  />
                )}

                {/* Right Section: Text */}
                <Box
                  style={{
                    alignItems: 'center', // Vertically center the content inside this Box
                    justifyContent: 'center', // Center the content horizontally
                    textAlign: 'left',
                  }}>
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: 'bold',
                      textShadow: '0px 4px 6px rgba(0, 0, 0, 0.4)',
                    }}
                  >
                    {professional.name}
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{ textShadow: '0px 4px 6px rgba(0, 0, 0, 0.4)' }}
                  >
                    {professional.address}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ textShadow: '0px 4px 6px rgba(0, 0, 0, 0.4)' }}
                  >
                    Open today: 08:00-20:00
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Horizontal Section for Employee and Services */}
          <Grid item xs={12}>
            <Divider style={{ marginBottom: '2rem' }} />
          </Grid>

          {/* Employee and Services Section */}
          <Grid item xs={12} style={{ zIndex: '100', marginTop: '-10rem' }}>
            <Box
              style={{
                backgroundColor: 'white',
                padding: '1.5rem',
                borderRadius: '10px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                maxWidth: '1200px',
                border: '1px solid #ddd', // Light gray border
                margin: '0 auto',
              }}
            >
              <Grid container spacing={3}>
                {/* Left Section: Employee Selection */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    Select Employee
                  </Typography>
                  <Controller
                    name="companyemployeeid"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={selectedEmployee ? selectedEmployee.id : ''}
                        fullWidth
                        onChange={handleEmployeeSelect}
                        renderValue={(selected) => {
                          const employee = employees.find((emp) => emp.id === selected);
                          if (!employee) return null;
                          return (
                            <Box display="flex" alignItems="center">
                              <Avatar
                                src={employee.image || ''}
                                alt={employee.name}
                                style={{ marginRight: 8 }}
                              >
                                {employee.image ? '' : employee.name.charAt(0)}
                              </Avatar>
                              <Typography>{employee.name}</Typography>
                            </Box>
                          );
                        }}
                      >
                        {employees.length > 0 ? (
                          employees.map((employee) => (
                            <MenuItem key={employee.id} value={employee.id}>
                              <ListItemAvatar style={{ minWidth: '40px' }}>
                                <Avatar src={employee.image} alt={employee.name} />
                              </ListItemAvatar>
                              {employee.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No employees available</MenuItem>
                        )}
                      </Select>
                    )}
                  />
                </Grid>

                {/* Right Section: Service Selection */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    Select Services
                  </Typography>
                  <ServiceSelector
                    services={services}
                    selectedServices={selectedServices}
                    onSelect={handleServiceSelect}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>


          {/* Section for Calendar, Time Slots, and Recap */}
          <Grid item xs={12} container spacing={3} justifyContent="center" style={{ marginTop: '2rem' }}>
            {/* Left Section: Calendar and Time Slots */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                style={{
                  backgroundColor: 'white',
                  padding: '1rem',
                  borderRadius: '10px', // Adding border radius for rounded corners
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Adding shadow for depth
                }}
              >
                <Typography variant="h6">Select Date and Time</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateCalendar
                    value={selectedDate}
                    onChange={handleDateChange}
                    shouldDisableDate={shouldDisableDate}
                    onMonthChange={handleMonthChange}
                  />
                </LocalizationProvider>
                <Box marginTop={2}>
                  <TimeSlotList
                    timeSlots={timeSlots}
                    selectedSlot={selectedSlot}
                    onSlotSelect={handleSlotSelect}
                  />
                </Box>
              </Box>
            </Grid>

            {/* Right Section: Booking Recap */}

            <Grid item xs={12} sm={6} md={4}  >
              <Box
                style={{
                  backgroundColor: 'white',
                  padding: '1.5rem',
                  borderRadius: '10px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  border: '1px solid #ddd',
                }}
              >
                <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                  Booking Summary
                </Typography>

                {/* Professional Information */}
                <Box style={{ marginBottom: '1rem' }}>
                  <Typography variant="body1">
                    <strong>Company:</strong> {professional.name}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Address:</strong> {professional.address}
                  </Typography>
                </Box>

                {/* Selected Employee */}
                <Box style={{ marginBottom: '1rem' }}>
                  <Typography variant="body1">
                    <strong>Selected Employee:</strong>{' '}
                    {selectedEmployee ? selectedEmployee.name : 'None'}
                  </Typography>
                </Box>

                {/* Highlighted Selected Date and Slot */}
                <Box
                  style={{
                    backgroundColor: '#f0f4ff',
                    padding: '1rem',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    marginBottom: '1.5rem',
                  }}
                >
                  <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                    Selected Date and Time
                  </Typography>
                  <Typography variant="body1">
                    <strong>Date:</strong>{' '}
                    {selectedDate ? selectedDate.toLocaleDateString() : 'Not Selected'}
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: '0.5rem' }}>
                    <strong>Time Slot:</strong>{' '}
                    {selectedSlot ? (
                      new Date(selectedSlot.startDate).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                    ) : (
                      'Not Selected'
                    )}
                  </Typography>
                </Box>

                {/* Selected Services */}
                <Box style={{ marginBottom: '1.5rem' }}>
                  <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                    Selected Services:
                  </Typography>
                  {selectedServices.length > 0 ? (
                    <Box>
                      {selectedServices.map((currentservice) => {
                        const service = services.find((s) => s.id === currentservice.id);
                        return (
                          <Box
                            key={service.id}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{
                              padding: '0.5rem 0',
                              borderBottom: '1px solid #f0f0f0',
                            }}
                          >
                            <Typography variant="body2">{t(service.name)}</Typography>
                            <Typography variant="body2" style={{ textAlign: 'center' }}>
                              {service.duration} mins
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: 'right' }}>
                              ${service.price}
                              {/* .toFixed(2) */}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  ) : (
                    <Typography>No services selected</Typography>
                  )}
                </Box>

                {/* Total Duration and Price */}
                <Box
                  style={{
                    paddingTop: '1rem',
                    marginTop: '1rem',
                    borderTop: '2px solid #ddd',
                  }}
                >
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Total Duration:
                    </Typography>
                    <Typography variant="body1">{totalDuration} minutes</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" style={{ marginTop: '0.5rem' }}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Total Price:
                    </Typography>
                    <Typography variant="body1">${totalPrice.toFixed(2)}</Typography>
                  </Box>
                </Box>

              </Box>


              <Box marginTop={2}>
                {!isUserConnected ? (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Please log in to proceed with your booking:
                    </Typography>
                    <Box display="flex" justifyContent="center" gap={2}>
                      <Button
                        variant="outlined"
                        onClick={() => setLoginPopupOpen(true)}
                        style={{ backgroundColor: '#f5f5f5', color: '#333' }}
                      >
                        Login
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setManualFormOpen(true)}
                        style={{ backgroundColor: '#664de4', color: '#fff' }}
                      >
                        Continue without signing in
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#664de4', color: '#fff' }}
                    type='submit'
                    fullWidth
                  >
                    Book Now
                  </Button>
                )}
                
                {/* onClick={() => handleFormSubmit()} */}

                {/* DiaryTree Login Popup */}
                <Dialog open={loginPopupOpen} onClose={() => setLoginPopupOpen(false)}>
                  <DialogTitle>Login with DiaryTree</DialogTitle>
                  <DialogContent>
                    <TextField
                      label="Email"
                      fullWidth
                      margin="normal"
                      value={credentials.email}
                      onChange={(e) =>
                        setCredentials({ ...credentials, email: e.target.value })
                      }
                    />
                    <TextField
                      label="Password"
                      type="password"
                      fullWidth
                      margin="normal"
                      value={credentials.password}
                      onChange={(e) =>
                        setCredentials({ ...credentials, password: e.target.value })
                      }
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setLoginPopupOpen(false)} color="secondary">
                      Cancel
                    </Button>
                    <Button
                      onClick={handleLogin}
                      variant="contained"
                      style={{ backgroundColor: '#664de4', color: '#fff' }}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} /> : 'Login'}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* Manual Form Popup */}
                <Dialog open={manualFormOpen} onClose={() => setManualFormOpen(false)}>
                  <DialogTitle>Enter Your Details</DialogTitle>
                  <DialogContent>
                    <TextField
                      label="Name"
                      fullWidth
                      margin="normal"
                      value={userDetails.name}
                      onChange={(e) =>
                        setUserDetails({ ...userDetails, name: e.target.value })
                      }
                    />
                    <TextField
                      label="Email"
                      fullWidth
                      margin="normal"
                      value={userDetails.email}
                      onChange={(e) =>
                        setUserDetails({ ...userDetails, email: e.target.value })
                      }
                    />
                    <TextField
                      label="Phone Number"
                      fullWidth
                      margin="normal"
                      value={userDetails.phoneNumber}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          phoneNumber: e.target.value,
                        })
                      }
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setManualFormOpen(false)} color="secondary">
                      Cancel
                    </Button>
                    <Button
                      onClick={handleManualFormSubmit}
                      variant="contained"
                      style={{ backgroundColor: '#664de4', color: '#fff' }}
                      disabled={
                        !userDetails.name || !userDetails.email || !userDetails.phoneNumber
                      }
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>

            </Grid>
          </Grid>


        </Grid>
      </form>
    </div>



  );
};

export default ProfessionalAvailability;
