import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Badge, TextField, Button, Select, MenuItem, List, IconButton, ListItem,
  ListItemText, ListItemAvatar, Avatar, Grid, Box, styled, Paper, Divider, Popover, ButtonBase,
  Card, CardContent, CardActionArea, CardMedia, InputAdornment, RadioGroup,useMediaQuery 
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from "moment";
import { Rate } from "antd";

import {
  LoadScript,
  StandaloneSearchBox,
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import { Row, Col, Modal, Typography } from "antd";
import { DateTimePicker } from "@mui/x-date-pickers";
// import AdapterDateFns from '@mui/lab/AdapterDateFns'; 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, PickersDay, DateCalendar } from '@mui/x-date-pickers';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
// import   AdapterDateFns from '@mui/x-date-pickers/AdapterDateFns';


// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { CloseOutlined, DeleteFilled } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { companyService, authenticationService } from "../../../app/services";
import MapPicker from "../../MapPicker";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

import { Scheduler } from "./Scheduler";
import { DataObjectSharp, DriveFileRenameOutlineOutlined, SortOutlined, SearchOutlined, DeleteOutlined } from "@mui/icons-material";
import TextArea from "antd/es/input/TextArea";

import ServiceCard from './ServiceCard';
import TimeSlotList from './TimeSlotList';
import ServiceSelector from './ServiceSelector';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { red } from "@mui/material/colors";




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const AppointmentForm = forwardRef(({
  visible, newForm, formdata, setFormData, closeModal, handleSubmitForm, handleDeleteSubmit, onFinish,
}, ref) => {

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      id: formdata?.id || "",
      companyid: formdata?.companyid || "",
      companyemployeeid: formdata?.companyemployeeid || [],
      companyemployeeservices: formdata?.companyemployeeservices || [],
      title: formdata?.title || "",
      startDate: formdata?.startDate || null,
      endDate: formdata?.endDate || null,
      calendarid: formdata?.calendarid || "1",
      description: formdata?.description || "",
      location: formdata?.location || "",
      participants: formdata?.participants || [],
      isEmployeeOwner: formdata?.isEmployeeOwner
    },
    // resolver: yupResolver(schema),

  });

  useImperativeHandle(ref, () => ({
    resetForm: () => reset()
  }));
  const initialValue = new Date();

  const isMobile = useMediaQuery('(max-width:600px)');
  const [selectedProfessionalType, setSelectedProfessionalType] = useState({});
  const [scheduleView, setScheduleView] = useState("week");
  const [currentAddress, setCurrentAddress] = useState("");

  const [professionalTypes, setProfessionalTypes] = useState();


  const [professionals, setProfessionals] = useState([]);
  const [showProfessionalsList, setShowProfessionalsList] = useState(false);
  const [showProfessionalSchedule, setShowProfessionalSchedule] = useState(false);

  const [selectedDate, setSelectedDate] = useState(initialValue);
  const [calendarDate, setCalendarDate] = useState(new Date());

  const [availabilities, setAvailabilities] = useState([]);

  const [selectedProfessional, setSelectedProfessional] = useState("");

  const [employees, setEmployees] = useState([]);  
  const [selectedEmployee, setSelectedEmployee] = useState(
    { id: '0', name: 'Any Employee', role: '', image: '' }
  );


  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState("");///TODO


  const [totalDuration, setTotalDuration] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0.0);

  const [currentlatitude, setCurrentLatitude] = useState(0);
  const [currentlongitude, setCurrentLongitude] = useState(0);

  // State to keep track of the number of days to fetch
  const [daysToFetch, setDaysToFetch] = useState(10);
  const [currentShownEndDate, setCurrentShownEndDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState(null); // Add state for selected slot
  const [timeSlots, setTimeSlots] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);///TODO
  const [libraries] = useState(["places"]);
  const [selectedName, setSelectedName] = useState("");
  const [location, setLocation] = useState('');
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });

  const [anchorEl, setAnchorEl] = useState(null);

  const mapRef = useRef(null);

  const user = useSelector(state=>state.user);

  const handleClick = (event, name) => {
    setAnchorEl(event.currentTarget);
    setSelectedName(name);
  };

  const handleParticipantSearch = async (event) => {
    //debugger;
    const query = event.target.value;

    try {
      // Call your API to search for users
      const result = await authenticationService.searchUserByEmailAndNumber(query);

      console.log("Participant search result:", result);

      if (result != null && result.data != null) {
        // If the result is a single object, convert it to an array to standardize the structure
        const resultsArray = result.error != 'NoResult' ? [result.data] : [];
        setSearchResults(resultsArray);
      } else {
        // If no results, reset the search results state
        setSearchResults([]);
      }
    } catch (error) {
      console.error('Error fetching participants:', error);
      setSearchResults([]);
    }
  };

  const handleClientSearch = async (event) => {
    const query = event.target.value;

    try {


      if (query && query.length > 0)
      {
        console.log("handle client search");
        const result = await authenticationService.searchUserByEmailAndNumber(query);
        if (result != null && result.data != null) {
          const resultsArray = result.error != 'NoResult' ? [result.data] : [];
          setClients(resultsArray);
        } else {
          // If no results, reset the search results state
          setClients([]);
      }
    }
    } catch (error) {
      console.error('Error fetching clients:', error);
      setSearchResults([]);
    }
  };

  const handleClientSelect = (client) => {

    setSelectedParticipants([...selectedParticipants, client]);
    setClients([]);
  };

  const handleEmployeeSearch = async (event) => {
    //debugger;
    const query = event.target.value;

    try {

      console.log("handle employee search");
    //   // Call your API to search for users
    //   const result = await authenticationService.searchUserByEmailAndNumber(query);

    //   console.log("Participant search result:", result);

    //   if (result != null && result.data != null) {
    //     // If the result is a single object, convert it to an array to standardize the structure
    //     const resultsArray = result.error != 'NoResult' ? [result.data] : [];
    //     setSearchResults(resultsArray);
    //   } else {
    //     // If no results, reset the search results state
    //     setSearchResults([]);
    //   }
    } catch (error) {
      console.error('Error fetching Employees:', error);
    }
  };


  const handleParticipantSelect = (participant) => {

    setSelectedParticipants([...selectedParticipants, participant]);
    setSearchResults([]);
  };

  const handleRemoveParticipant = (id) => {
    setSelectedParticipants(selectedParticipants.filter(p => p.id !== id));
  };

  ////Manage autocomplete
  // const { isLoaded, loadError } = useLoadScript(
  //   {
  //   googleMapsApiKey: 'AIzaSyB_j8NP_ZfkMFGHNxqsCL_pPhEIBlBp9As', // Replace with your API key
  //    libraries : libraries,
  // }
  // );

  const [autocomplete, setAutocomplete] = useState(null);
  const inputRef = useRef(null);

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const location = place.formatted_address;
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      setValue('location', location);
      setLocation(location);
      setCoordinates({ lat, lng });
      console.log('Location:', location);
      console.log('Latitude:', lat);
      console.log('Longitude:', lng);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  // if (loadError) return 'Error loading Google Maps API';
  // if (!isLoaded) return 'Loading...';

  // const initialValue = dayjs('2022-04-17');

  const { t } = useTranslation();

  ///////////////// DateCalendar
  function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
    // && highlightedDays.indexOf(props.day.date()) >= 0
    const isSelected = !props.outsideCurrentMonth;
    // 
    // overlap="circular"
    // badgeContent={isSelected ? '🌚' : undefined}
    // anchorOrigin={{
    //   vertical: 'top',
    //   horizontal: 'right',
    // }}
    return (
      <Badge
        key={props.day.toString()}

      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
      // fontSize: '5px',
      // height: '10px',
      // width: '5px',
      // borderRadius: '50%',
    );
  }


  const requestAbortController = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15]);

  const fetchHighlightedDays = (date) => {
    setIsLoading(false);
  };

  React.useEffect(() => {
    //debugger;
    fetchHighlightedDays(initialValue);
    if(user.user.typ == "Pro")
    {
      setSelectedProfessional(user.user.sub);
    }
    // setLocation(location);
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  ///////////////// end DateCalendar

  useEffect(() => {
    // Check if browser supports geolocation
    if (navigator.geolocation) {
      // Get current position using navigator's geolocation
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLatitude(position.coords.latitude);
          setCurrentLongitude(position.coords.longitude);
          setCurrentAddress(position.coords.address);
        },
        (error) => {
          fetch("https://ipapi.co/json/")
            .then((response) => response.json())
            .then((data) => {
              setCurrentLatitude(data.latitude);
              setCurrentLongitude(data.longitude);
              setCurrentAddress(data.address);
            })
            .catch((error) => {
              // setError(`Error retrieving location: ${error.message}`);
              // setIsGoogleApiLoaded(true);
            });
          // setError(`Error retrieving location: ${error.message}`);
          // setIsGoogleApiLoaded(true);
        }
      );
    } else {
      // If geolocation is not supported, get location based on IP address
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          setCurrentLatitude(data.latitude);
          setCurrentLongitude(data.longitude);
          setCurrentAddress(data.address);
          // setIsGoogleApiLoaded(true);
        })
        .catch((error) => {
          // setError(`Error retrieving location: ${error.message}`);
          // setIsGoogleApiLoaded(true);
        });
    }
    //autocomplete = new google.maps.places.Autocomplete((this.refs.autoCompletePlaces), {types: ['geocode']});
  }, []);

  const { isLoaded, loadError } = useLoadScript(
    {
      googleMapsApiKey: 'AIzaSyB_j8NP_ZfkMFGHNxqsCL_pPhEIBlBp9As', // Replace with your API key
      libraries: libraries,
    }
  );

  useEffect(() => {
    companyService
      .getAllCategories()
      .then((categories) => {
        setProfessionalTypes(categories);
      })
      .catch((error) => {
        console.error("Failed to fetch professional types:", error);
      });
  }, []);

  useEffect(() => {
    if (formdata) {
      setValue("id", formdata.id || "");
      setValue("title", formdata.title || "");
      setValue("startDate", formdata.startDate || null);
      setValue("endDate", formdata.endDate || null);
      setValue("calendarid", formdata.calendarid || "1");
      setValue("description", formdata.description || "");
      setValue("companyid", formdata.companyid || "");
      setValue("companyemployeeid", formdata.companyemployeeid || "");
      setValue("companyemployeeservices", formdata.companyemployeeservices || []);
      setValue("location", formdata.location || "");
      setValue("participants", formdata.participants || []);
      setValue("isEmployeeOwner", formdata?.isEmployeeOwner);


      setSelectedServices(formdata.companyemployeeservices|| []);
      setSelectedParticipants(formdata.participants || []);
    }
  }, [formdata, setValue]);


  useEffect(() => { 
    if (selectedProfessional) {
      var companyUserName = "";
        if(user.user.typ == "Pro")
        {
          companyUserName = selectedProfessional;
        }
        else
        {

          companyUserName = selectedProfessional.username;
        }

        if(companyUserName != "")
        {
                    // Fetch employees for the selected professional
                    companyService.getCompanyEmployees(companyUserName)
                    .then(response => {
                      const anyEmployee = { id: '0', name: 'Any Employee', role: '', image: '' };
                      setEmployees([anyEmployee, ...response.data]);
                      return true;
                    });
                    
                    companyService.getCompanyServicesByEmployee(companyUserName, "")
                    .then(response => {
                      setServices(response);
                      return true;
                    });

        }

    }
  }, [selectedProfessional]);


  useEffect(() => {
    if (selectedProfessional && selectedEmployee) {
      // Fetch employees for the selected professional

      companyService.getCompanyServicesByEmployee(selectedProfessional.username, selectedEmployee.username)
        .then(response => {
          setServices(response);
          setValue('companyemployeeid', selectedEmployee.username);
          return true;
        });
    }
  }, [selectedEmployee]);


  useEffect(() => {
    const duration = selectedServices.reduce((total, service) => {
      return total + parseInt(service.duration, 10);
    }, 0);
    setTotalDuration(duration);

    const price = selectedServices.reduce((total, service) => {
      return total + parseFloat(service.price);
    }, 0.0);
    setTotalPrice(price);
  }, [selectedServices]);

  useEffect(() => {
    if (selectedEmployee && selectedServices && selectedServices.length > 0 &&  user.user.typ != "Pro") {

      const totalDuration = calculateTotalDuration();

      companyService
        .getCompanyDisponibilities(selectedProfessional, selectedEmployee, selectedDate, 1, totalDuration)
        .then((availabilities) => {
          setTimeSlots(availabilities);
        })
        .catch((error) => {
          console.error('Failed to fetch time slots:', error);
        });


    }
  }, [selectedEmployee, selectedDate, selectedServices]);


  const calculateTotalDuration = () => {
    return selectedServices.reduce((total, service) => total + parseInt(service.duration, 10), 0);
    //selectedServices.reduce((total, service) => total + service.duration, 0);
  };

  const handleFormSubmit = (data) => {
    if(selectedEmployee.id != "0")
    {
      data.companyemployeeid = selectedEmployee.id;
    }


    data.participants = selectedParticipants;

    handleSubmitForm(data);
    onFinish();
  };

  const handleDeleteClick = () => {
    handleDeleteSubmit(formdata);
  };

  const handleBackToFormClick = () => {
    setSelectedProfessionalType(null);
    setShowProfessionalsList(false);
    setShowProfessionalSchedule(false);
  };

  // State to track the selected day
  const [selectedDay, setSelectedDay] = useState(null);

  // Group availabilities by day
  const groupedAvailabilities = availabilities.reduce((grouped, availability) => {
    const day = new Date(availability.startDate).toLocaleDateString();

    if (!grouped[day]) {
      grouped[day] = [];
    }

    grouped[day].push(availability);

    // Update maxDate if the current availability's start date is greater
    const availabilityStartDate = new Date(availability.startDate);
    if (availabilityStartDate > currentShownEndDate) {
      setCurrentShownEndDate(availabilityStartDate);
    }


    return grouped;
  }, {});

  // Handle day button click
  const handleDayClick = (day) => {
    setSelectedDay(day);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);//
  };
  // Handle the selection of a professional

  const handleViewScheduleClick = (professional) => {
    if (professional) {
      console.log(professional);

      setSelectedProfessional(professional);
      setValue('companyid', professional.id);
      setShowProfessionalsList(false);
      setShowProfessionalSchedule(true);

      companyService.getCompanyServicesByEmployee(professional.username, "")
        .then(response => {
          setServices(response);
          return true;
        });
      // Update the value of the field with the new JSON string
      setValue('location', professional.address);
      // fetchAvailabilities(professional);
    }
  };

  const handleRescedulingClick = (context) => {
    if (context.companyid != '') {
      companyService.getCompaniesByCategoryAndLocationAndCompanyid("", {}, context.companyid)
        .then(response => {
          handleViewScheduleClick(response[0]);
          return true;
        });
    }
  }

  const showCurrentDateCaption = (date) => {
    var monthNameText = date
      .toLocaleString("default", { month: "long" })
      .toUpperCase();

    var yearNameText = date.getFullYear();

    return monthNameText + "," + yearNameText;
  };

  const changeCalendarDate = async (direction) => {
    var current = new Date(selectedDate);

    current = new Date(
      selectedDate.setDate(calendarDate.getDate() + 7 * direction)
    );

    setCalendarDate(current);
  };

  //1st Step of the process -  Handle the selection of a professional Type
  const handleProfessionalTypeClick = (professionalType) => {
    setSelectedProfessionalType(professionalType);
    setShowProfessionalsList(true);
    setShowProfessionalSchedule(false);
  };

  const handleEmployeeSelect = (event) => {
    const employeeId = event.target.value;
    const employee = employees.find(emp => emp.id === employeeId);
    setSelectedEmployee(employee);
  };

  const handleServiceSelect = (service) => {

    setSelectedServices((prevSelectedServices) => {

      if (prevSelectedServices.includes(service)) {
        // Remove service if already selected
        return prevSelectedServices.filter((s) => s.id !== service.id);
      } else {
        // Add service if not already selected
        // // Parse the current services if it's a JSON string, or initialize it as an array if it's empty
        // const serviceList = selectedServices && selectedServices.length > 0? JSON.parse(selectedServices) : [];

        // // Add the new service to the list (ensuring newService is an object with the required fields)
        // serviceList.push(service);

        return [...prevSelectedServices, service];
      }
    });

    // Update the value of the field with the new JSON string
    setValue('companyemployeeservices', selectedServices);
  };

  const onCancel = (context) => {
    if (context.currentTarget && context.currentTarget.ariaLabel == 'Close') {

      setSelectedProfessionalType({});
      setShowProfessionalsList(false);
      setShowProfessionalSchedule(false);
      closeModal(context);
      setSelectedParticipants([]);
    }
  }

  const handleSlotSelect = (slot) => {
    setSelectedSlot(slot);
    scheduleSelectAction(slot)
  };

  // 2nd Step of the process - show professionals List screen
  if (showProfessionalsList) {
    // Render professionals list and map view
    return (
      <Modal
        key={"modalAppointmentForm"}
        className={"gx-modal gx-modal-wide choose-professionals-popup"}
        open={visible}
        title={newForm ? t("AppointmentForm.newSchedule") : t("AppointmentForm.editSchedule")}
        closeIcon={<CloseOutlined style={{ fontSize: 14 }} />}
        onCancel={onCancel}
        footer={
          <footer>
            <Grid xs={12} item container>
              <Grid xs={1} item>
                {/* helperText={errors.description?.message} */}
                <Button
                  type="button"
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteFilled />}
                  onClick={handleDeleteClick}
                  className="delete-btn"
                >
                  {t("AppointmentForm.delete")}
                </Button>
              </Grid>
              <Grid xs={9} item />
              <Grid xs={2} item>
                <Button
                  type="submit"
                  variant="contained"
                  className="submit-btn"
                >
                  {newForm ? t("AppointmentForm.add") : t("AppointmentForm.update")}
                </Button>
              </Grid>
            </Grid>
          </footer>
        }
      >
        <MapPicker
          Lat={currentlatitude}
          Lng={currentlongitude}
          address={currentAddress}
          selectedProfessionalType={selectedProfessionalType}
          handleViewScheduleClick={handleViewScheduleClick}
        />
      </Modal>
    );
  }
  // 4th Step of the process - Handle the selection of a schedule
  const scheduleSelectAction = (appointmentData) => {
    //
    setFormData({
      id: appointmentData.id,
      title: appointmentData.title,
      description: appointmentData.description,
      calendarid: appointmentData.calendarid,
      startDate:
        appointmentData.startDate != null
          ? appointmentData.startDate
          : moment(), //.format('YYYY/MM/DD'),
      endDate:
        appointmentData.endDate != null
          ? appointmentData.endDate
          : moment().add(30, "minutes"), //.format('YYYY/MM/DD'),
      calendars: formdata.calendars,
      companyid: appointmentData.companyid,
      isEmployeeOwner: appointmentData.isEmployeeOwner,
      companyemployeeid: appointmentData.companyemployeeid,
      companyemployeeservices: selectedServices,
      location: appointmentData?.location || "",
      participants: selectedParticipants || []

    });
  };

  const shouldDisableDate = (date) => {
    return date < new Date(); // Disable dates before today
  };

  // 3rd Step of the process -  show professional schedule screen
  if (showProfessionalSchedule) {

    const mapOptions = {
      zoomControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      draggableCursor: "pointer"
    };

    const mapOnLoad = (map) => {
      mapRef.current = map;
    };

    return (
      <Modal
        key={"modalAppointmentForm"}
        className={"gx-modal gx-modal-wide availabilities-pro-popup"}
        open={visible}
        title={newForm ? t("AppointmentForm.newSchedule") : t("AppointmentForm.editSchedule")}
        closeIcon={<CloseOutlined style={{ fontSize: 14 }} />}
        onCancel={onCancel}
        footer={
          <footer>
            <Grid xs={12} item container>
              <Grid xs={1} item>
                {/* helperText={errors.description?.message} */}
                <Button
                  type="button"
                  variant="contained"
                  className="delete-btn"
                  startIcon={<DeleteFilled />}
                  onClick={handleDeleteClick}
                >
                  {t("AppointmentForm.delete")}
                </Button>
              </Grid>
              <Grid xs={9} item />
              <Grid xs={2} item>
                <Button
                  type="submit"
                  variant="contained"
                  className="submit-btn"
                  onClick={handleSubmit(handleFormSubmit)}
                >
                  {newForm ? (showProfessionalSchedule ? t("AppointmentForm.book") : t("AppointmentForm.add")) : t("AppointmentForm.update")}
                </Button>
              </Grid>
            </Grid>
          </footer>
        }
      >
        <form className="signup-form" spacing="5">
          <input type="hidden" {...register("companyid")} value={selectedProfessional.id} />
          <input type="hidden" {...register("companyemployeeid")} value={selectedEmployee ? selectedEmployee.id : ''} />
          <input type="hidden" {...register("companyemployeeservices")} value={selectedServices ? selectedServices : []} />
          <input type="hidden" {...register("startDate")} value={selectedSlot ? selectedSlot.startDate : ''} />
          <input type="hidden" {...register("endDate")} value={selectedSlot ? selectedSlot.endDate : ''} />
          <input type="hidden" {...register("location")} value={selectedProfessional ? selectedProfessional.address : ''} />
          <Grid container spacing={isMobile ? 1 : 2}>
      {/* First Column: Company Description */}
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={5}
        spacing={1}
        style={{ flexDirection: 'row' }}
      >
        {/* !isMobile ? 'column' :  */}
        <Grid item container xs={12} >
          {/* Company Info */}
          <Grid item xs={12} sm={6}>
            <Box>
              {selectedProfessional.logo ? (
                <img
                  src={`data:image/png;base64,${selectedProfessional.logo}`}
                  alt={`${selectedProfessional.Name} Logo`}
                  style={{
                    width: isMobile ? "80px" : "100px",
                    height: isMobile ? "80px" : "100px",
                  }}
                />
              ) : (
                <Avatar
                  src={selectedProfessional.logo}
                  alt={selectedProfessional.name}
                  style={{
                    width: isMobile ? 60 : 80,
                    height: isMobile ? 60 : 80,
                    marginBottom: 16,
                  }}
                />
              )}
              <Typography variant={isMobile ? "h5" : "h4"}>
                {selectedProfessional.name}
              </Typography>
              <Typography variant="body1">
                {selectedProfessional.address}
              </Typography>
              {selectedProfessional.rating && (
                <Rate value={selectedProfessional.rating} disabled allowHalf />
              )}
              <Typography variant="body2">Open today: 08:00-20:00</Typography>
            </Box>
          </Grid>

          {/* Google Map */}
          <Grid item xs={12} sm={6}>
            <GoogleMap
              mapContainerStyle={{
                height: isMobile ? "150px" : "100%",
                width: "100%",
                borderRadius: "12px",
              }}
              center={{
                lat: selectedProfessional.coordinate.lat,
                lng: selectedProfessional.coordinate.lng,
              }}
              zoom={13}
              options={mapOptions}
              onLoad={mapOnLoad}
            >
              <Marker
                key={selectedProfessional.id}
                position={{
                  lat: selectedProfessional.coordinate.lat,
                  lng: selectedProfessional.coordinate.lng,
                }}
                icon="http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
              />
            </GoogleMap>
          </Grid>
        </Grid>

        {/* Employee and Services Selection */}
        <Grid xs={12} item container
          
          style={{
            marginTop: isMobile ? "10px" : "0",
          }}
        >
          {/* Employee Selection */}
          <Grid item xs={12}>
            <Box>
              <Typography variant={isMobile ? "body1" : "h6"}>
                Selected Employee
              </Typography>
              <Select
                value={selectedEmployee ? selectedEmployee.id : ''}
                fullWidth
                onChange={handleEmployeeSelect}
                renderValue={(selected) => {
                  const employee = employees.find((emp) => emp.id === selected);
                  if (!employee) return null;
                  return (
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={employee.image || ''}
                        alt={employee.name}
                        style={{ marginRight: 8 }}
                      >
                        {employee.image ? '' : employee.name.charAt(0)}
                      </Avatar>
                      <Typography>{employee.name}</Typography>
                    </Box>
                  );
                }}
              >
                {employees.length > 0 ? (
                  employees.map((employee) => (
                    <MenuItem key={employee.id} value={employee.id}>
                      <ListItemAvatar>
                        <Avatar src={employee.image || ''} alt={employee.name}>
                          {employee.image ? '' : employee.name.charAt(0)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={employee.name}
                        secondary={employee.role}
                      />
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="None">
                    <ListItemText primary="No employees available" />
                  </MenuItem>
                )}
              </Select>
            </Box>
          </Grid>

          {/* Service Selection */}
          <Grid item xs={12}>
            <Box marginTop={isMobile ? 1 : 2}>
              <Typography variant={isMobile ? "body1" : "h6"}>
                Select Services
              </Typography>
              <ServiceSelector
                services={services}
                selectedServices={selectedServices}
                onSelect={handleServiceSelect}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* Third Column: Slot Selection */}
      <Grid item md={3} xs={12} // Or any breakpoint value
      >
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        backgroundColor: 'white',
        borderRadius: 2,
        boxShadow: 3,
        overflow: 'hidden',
        padding: 1,
      }}
    >
      <DateCalendar
        defaultValue={initialValue}
        loading={isLoading}
        value={selectedDate ?? ''}
        onMonthChange={() => {}}
        onChange={handleDateChange}
        renderLoading={() => <div>Loading...</div>}
        shouldDisableDate={shouldDisableDate}
        sx={{
          width: '100%',
          '& .MuiCalendarPicker-root': {
            width: '100%',
            minWidth: 250,
          },
        }}
      />
    </Box>
  </LocalizationProvider>

  <Box
    sx={{
      width: '100%',
      maxWidth: '100%',
      maxHeight: '300px',
      padding: 1,
    }}
  >
    <TimeSlotList
      professional={selectedProfessional}
      employee={selectedEmployee}
      timeSlots={timeSlots}
      onSlotSelect={handleSlotSelect}
      selectedDate={selectedDate}
      interval={30}
    />
  </Box>
</Grid>

{/* maxWidth:!isMobile ? "50%" : "100%", flexBasis:!isMobile ? "50%" : "100%"  */}
      {/* Fourth Column: Recapitulation */}
      <Grid item xs={12} sm={6} md={4} style={{ flexBasis:isMobile ? "100%" : "",maxWidth:isMobile ? "100%" : ""}}>
      <Box
                style={{
                  backgroundColor: 'white',
                  padding: '1.5rem',
                  borderRadius: '10px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  border: '1px solid #ddd',
                }}
              >
                <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                  Booking Summary
                </Typography>

                {/* Professional Information */}
                <Box style={{ marginBottom: '1rem' }}>
                  <Typography variant="body1">
                    <strong>Company:</strong> {selectedProfessional.name}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Address:</strong> {selectedProfessional.address}
                  </Typography>
                </Box>

                {/* Selected Employee */}
                <Box style={{ marginBottom: '1rem' }}>
                  <Typography variant="body1">
                    <strong>Selected Employee:</strong>{' '}
                    {selectedEmployee ? selectedEmployee.name : 'None'}
                  </Typography>
                </Box>

                {/* Highlighted Selected Date and Slot */}
                <Box
                  style={{
                    backgroundColor: '#f0f4ff',
                    padding: '1rem',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    marginBottom: '1.5rem',
                  }}
                >
                  <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                    Selected Date and Time
                  </Typography>
                  <Typography variant="body1">
                    <strong>Date:</strong>{' '}
                    {selectedDate ? selectedDate.toLocaleDateString() : 'Not Selected'}
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: '0.5rem' }}>
                    <strong>Time Slot:</strong>{' '}
                    {selectedSlot ? (
                      new Date(selectedSlot.startDate).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })
                    ) : (
                      'Not Selected'
                    )}
                  </Typography>
                </Box>

                {/* Selected Services */}
                <Box style={{ marginBottom: '1.5rem' }}>
                  <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                    Selected Services:
                  </Typography>
                  {selectedServices.length > 0 ? (
                    <Box>
                      {selectedServices.map((currentservice) => {
                        const service = services.find((s) => s.id === currentservice.id);
                        return (
                          <Box
                            key={service.id}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{
                              padding: '0.5rem 0',
                              borderBottom: '1px solid #f0f0f0',
                            }}
                          >
                            <Typography variant="body2">{t(service.name)}</Typography>
                            <Typography variant="body2" style={{ textAlign: 'center' }}>
                              {service.duration} mins
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: 'right' }}>
                              ${service.price}
                              {/* .toFixed(2) */}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  ) : (
                    <Typography>No services selected</Typography>
                  )}
                </Box>

                {/* Total Duration and Price */}
                <Box
                  style={{
                    paddingTop: '1rem',
                    marginTop: '1rem',
                    borderTop: '2px solid #ddd',
                  }}
                >
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Total Duration:
                    </Typography>
                    <Typography variant="body1">{totalDuration} minutes</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" style={{ marginTop: '0.5rem' }}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Total Price:
                    </Typography>
                    <Typography variant="body1">${totalPrice.toFixed(2)}</Typography>
                  </Box>
                </Box>

              </Box>
        {/* <Box>
          <Typography variant={isMobile ? "body1" : "h6"}>Booking Summary</Typography>
          <ListItem>
            <ListItemText primary="Company" secondary={selectedProfessional.name} />
            <ListItemText primary="Address" secondary={selectedProfessional.address} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Selected Employee"
              secondary={selectedEmployee ? selectedEmployee.name : 'None'}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Selected Services"
              secondary={
                <ul>
                  {selectedServices.length > 0 ? (
                    selectedServices.map((currentService) => (
                      <li key={currentService.id}>{currentService.name}</li>
                    ))
                  ) : (
                    <li>No services selected</li>
                  )}
                </ul>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Selected Date"
              secondary={selectedDate ? selectedDate.toLocaleDateString() : 'None'}
            />
            <ListItemText
              primary="Selected Slot"
              secondary={
                selectedSlot ? (
                  new Date(selectedSlot.startDate).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                ) : (
                  'No slot selected'
                )
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Total Duration" secondary={`${totalDuration} minutes`} />
            <ListItemText primary="Total Price" secondary={`$${totalPrice.toFixed(2)}`} />
          </ListItem>
        </Box> */}
      </Grid>

      {/* Divider */}
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
        </form>
      </Modal>
    );
  }

  const initialDate = "2024-07-11T10:00:00";

  if(user.user.typ == "Pro")
  {
    return (  
      <Modal
      key={"modalAppointmentForm"}
      className={"gx-modal new-schedule-regular-popup"}
      open={visible}
      title={newForm ? t("AppointmentForm.newSchedule") : t("AppointmentForm.editSchedule")}
      closeIcon={<CloseOutlined style={{ fontSize: 14 }} />}
      onCancel={onCancel}
      footer={
        <footer>
          <Grid xs={12} item container style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} >
            <div>
              <Button type="button" variant="contained" className="delete-btn" startIcon={<DeleteFilled />} onClick={handleDeleteClick} >
                {t("AppointmentForm.delete")}
              </Button>
            </div>
            <div>
              <Button variant="contained" className="submit-btn" onClick={handleSubmit(handleFormSubmit)} >
                {newForm ? t("AppointmentForm.add") : t("AppointmentForm.update")}
              </Button>
            </div>
          </Grid>
        </footer>
      }
    >
      <Box display="flex">
        <Box flexGrow={1}>
          <form className="signup-form">
            <input type="hidden" className="input" defaultValue={formdata?.id} {...register("id")} />
            <input type="hidden" className="input" defaultValue={formdata?.companyid} {...register("companyid")} />
            <input type="hidden" className="input" defaultValue={formdata?.companyemployeeid} {...register("companyemployeeid")} />
            <input type="hidden" className="input" defaultValue={formdata?.companyemployeeservices} {...register("companyemployeeservices")} />
            <Grid container spacing={1}>
              <Grid item xs={12}>

                <Typography>{t("AppointmentForm.title")}</Typography>
                <div className="edit-schedule-input" style={{ margin: 0, position: "relative", display: "flex", alignItems: "center", margin: "10px 0 10px 0", }} >
                  <TextField label={t("AppointmentForm.typeEventName")} className="text-field" variant="outlined" fullWidth {...register("title", { defaultValue: formdata?.title || "", })}
                    error={errors.title !== undefined ? errors.title : undefined}
                  />
                </div>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography>{t("AppointmentForm.eventDates")}</Typography>
                </Grid>
                <Grid item xs={12} md={6} className="date-field">
                  <Typography>{t("AppointmentForm.starttime")}</Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs} 
                    adapterLocale="fr">
                    <Controller  
                      control={control}
                      name="startDate"
                      defaultValue={dayjs(initialDate)}
                      render={({ field }) => (
                        <DateTimePicker
                          {...field} className="text-field"
                          value={field.value ? dayjs(field.value) : dayjs(initialDate)}
                          onChange={(date) => field.onChange(date)}
                          format="DD/MM/YYYY HH:mm" 
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} className="date-field" >
                  <Typography>{t("AppointmentForm.endtime")}</Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      control={control}
                      name="endDate"
                      defaultValue={dayjs(initialDate)}
                      render={({ field }) => (
                        <DateTimePicker
                          {...field} className="text-field"
                          value={field.value ? dayjs(field.value) : dayjs(initialDate)}
                          onChange={(date) => field.onChange(date)}
                          format="DD/MM/YYYY HH:mm" 
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              {/* client section newForm && */}
              {newForm && (
                <Grid container item xs={12}>
                  <Typography>Select clients</Typography>
                  <Grid item xs={12} className="participant-selector-wrapper" >

                    {/* Search Input and Dropdown Results */}
                    <Box className="participant-search-container">
                      <TextField style={{ borderColor: '#ddd' }} className="text-field"
                        label="Rechercher les clients par email ou nom"
                        onChange={handleClientSearch}
                        fullWidth
                        margin="normal"
                      />

                      {/* Display search results in a dropdown */}
                      {clients.length > 0 && (
                        <Paper className="participant-search-dropdown" elevation={3}>
                          {clients.map((participant,index) => (
                            <ListItem
                              key={index}
                              onClick={() => handleClientSelect(participant)}
                            >
                              <ListItemAvatar>
                                <Avatar>
                                  {/* Display the first character of the username or a default 'U' */}
                                  {participant.username ? participant.username.charAt(0).toUpperCase() : "U"}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={participant.username || "Unknown User"} // Display username or fallback
                                secondary={participant.email} // Display email
                              />
                            </ListItem>
                          ))}
                        </Paper>
                      )}
                    </Box>

                    {/* Selected clients */}
                    <Box className="selected-participants">
                      <Grid container spacing={1}>
                        {selectedParticipants.length > 0 &&
                          selectedParticipants.map((client,index) => (
                            <ButtonBase
                              style={{ padding: "5px", borderRadius: '4px' }}
                              key={index}
                              onClick={(event) => handleClick(event, client.username || "Unknown User")}
                            >
                              <Grid container alignItems="center" spacing={1} className="participant-item">
                                {/* Avatar Section */}
                                <Grid item>
                                  <ListItemAvatar>
                                    <Avatar>
                                      {client.username ? client.username.charAt(0).toUpperCase() : "U"}
                                    </Avatar>
                                  </ListItemAvatar>
                                </Grid>
                                {/* Name Section */}
                                <Grid item>
                                  <Typography variant="body1" style={{ marginLeft: "8px" }}>
                                    {client.username || "Unknown User"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </ButtonBase>
                          ))}
                      </Grid>

                      {selectedName && (
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <Typography style={{ padding: '10px', fontWeight: 'bold' }}>
                            {selectedName}
                          </Typography>
                        </Popover>
                      )}
                    </Box>

                  </Grid>
                </Grid>
              )}

        {/* Employee and Services Selection */}
            <Grid xs={12} item container
                style={{
                  marginTop: isMobile ? "10px" : "0",
                }}
              >
                {/* Employee Selection */}
                <Grid item xs={12}>
                  <Box>
                    <Typography variant={isMobile ? "body1" : "h6"}>
                      Select Employee
                    </Typography>
                    <Select
                      value={selectedEmployee ? selectedEmployee.id : ''}
                      fullWidth
                      onChange={handleEmployeeSelect}
                      renderValue={(selected) => {
                        const employee = employees.find((emp) => emp.id === selected);
                        if (!employee) return null;
                        return (
                          <Box display="flex" alignItems="center">
                            <Avatar
                              src={employee.image || ''}
                              alt={employee.name}
                              style={{ marginRight: 8 }}
                            >
                              {employee.image ? '' : employee.name.charAt(0)}
                            </Avatar>
                            <Typography>{employee.name}</Typography>
                          </Box>
                        );
                      }}
                    >
                      {employees.length > 0 ? (
                        employees.map((employee) => (
                          <MenuItem key={employee.id} value={employee.id}>
                            <ListItemAvatar>
                              <Avatar src={employee.image || ''} alt={employee.name}>
                                {employee.image ? '' : employee.name.charAt(0)}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={employee.name}
                              secondary={employee.role}
                            />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="None">
                          <ListItemText primary="No employees available" />
                        </MenuItem>
                      )}
                    </Select>
                  </Box>
                </Grid>

                {/* Service Selection */}
                  <Grid item xs={12}>
                    <Box marginTop={isMobile ? 1 : 2}>
                      <Typography variant={isMobile ? "body1" : "h6"}>
                        Select Services
                      </Typography>
                      <ServiceSelector
                        services={services}
                        selectedServices={selectedServices}
                        onSelect={handleServiceSelect}
                      />
                    </Box>
                  </Grid>
                </Grid>         
              <Grid xs={12} item>
                <Typography>{t("AppointmentForm.description")}</Typography>
              </Grid>
              <Grid xs={12} item
                className="description-schedule-textarea"
                style={{
                  margin: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "flex-start",
                  margin: "10px 0 10px 0",
                  width: "100%"
                }}
              >
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      label={t("AppointmentForm.description")}
                      variant="outlined"
                      rows={3}
                      {...field}
                      error={errors.description !== undefined ? errors.description : ""}
                    />
                  )}
                />
                <SortOutlined
                  style={{
                    position: "absolute",
                    color: "var(--light-color)",
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Modal>
    );
  }
  else
  {
        const handleClose = () => {
          setAnchorEl(null);
        };
        const open = Boolean(anchorEl);
        const id = open ? 'user-popover' : undefined;
        // Show normal event creation form
        return (
          <Modal
            key={"modalAppointmentForm"}
            className={"gx-modal new-schedule-regular-popup"}
            open={visible}
            title={newForm ? t("AppointmentForm.newSchedule") : t("AppointmentForm.editSchedule")}
            closeIcon={<CloseOutlined style={{ fontSize: 14 }} />}
            onCancel={onCancel}
            footer={
              <footer>
                <Grid xs={12} item container style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }} >
                  <div>
                    <Button type="button" variant="contained" className="delete-btn" startIcon={<DeleteFilled />} onClick={handleDeleteClick} >
                      {t("AppointmentForm.delete")}
                    </Button>
                  </div>

                  {newForm ?
                    <></>
                    :
                    formdata?.companyid && !formdata?.isEmployeeOwner &&  (
                      <div>
                        <Button variant="contained" className="submit-btn" onClick={() => handleRescedulingClick(formdata)} >
                          Reschedule
                        </Button>
                      </div>
                    )
                  }
                  <div>
                    <Button variant="contained" className="submit-btn" onClick={handleSubmit(handleFormSubmit)} >
                      {newForm ? t("AppointmentForm.add") : t("AppointmentForm.update")}
                    </Button>
                  </div>
                </Grid>
              </footer>
            }
          >
            <Box display="flex">
              <Box flexGrow={1}>
                <form className="signup-form">
                  <input type="hidden" className="input" defaultValue={formdata?.id} {...register("id")} />
                  <input type="hidden" className="input" defaultValue={formdata?.companyid} {...register("companyid")} />
                  <input type="hidden" className="input" defaultValue={formdata?.companyemployeeid} {...register("companyemployeeid")} />
                  <input type="hidden" className="input" defaultValue={formdata?.companyemployeeservices} {...register("companyemployeeservices")} />
                  <Grid container spacing={1}>
                    <Grid item xs={12}>

                      <Typography>{t("AppointmentForm.title")}</Typography>
                      <div className="edit-schedule-input" style={{ margin: 0, position: "relative", display: "flex", alignItems: "center", margin: "10px 0 10px 0", }} >
                        <TextField label={t("AppointmentForm.typeEventName")} className="text-field" variant="outlined" fullWidth {...register("title", { defaultValue: formdata?.title || "", })}
                          error={errors.title !== undefined ? errors.title : undefined}
                        />
                      </div>
                      <Typography>{user.authenticated}</Typography>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Typography>{t("AppointmentForm.eventDates")}</Typography>
                      </Grid>
                      <Grid item xs={12} md={6} className="date-field">

                        <Typography>{t("AppointmentForm.starttime")}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Controller  
                            control={control}
                            name="startDate"
                            defaultValue={dayjs(initialDate)}
                            render={({ field }) => (
                              <DateTimePicker
                                {...field} className="text-field"
                                value={field.value ? dayjs(field.value) : dayjs(initialDate)}
                                onChange={(date) => field.onChange(date)}
                                format="DD/MM/YYYY HH:mm" 
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} md={6} className="date-field" >

                        <Typography>{t("AppointmentForm.endtime")}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Controller
                            control={control}
                            name="endDate"
                            defaultValue={dayjs(initialDate)}
                            render={({ field }) => (
                              <DateTimePicker
                                {...field} className="text-field"
                                value={field.value ? dayjs(field.value) : dayjs(initialDate)}
                                onChange={(date) => field.onChange(date)}
                                format="DD/MM/YYYY HH:mm" 
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    {newForm ? (
                      <Grid item xs={12} style={{ margin: "20px 0 10px 0" }}>
                        <Typography>Choose a professional:</Typography>
                        {Array.isArray(professionalTypes) ? (
                          <div className="professional-categories">
                            {professionalTypes.map((proftype) => (
                              <a
                                key={proftype.id}
                                variant={
                                  selectedProfessionalType === proftype.name
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() => handleProfessionalTypeClick(proftype)}
                              >
                                {proftype.name}
                              </a>
                            ))}
                          </div>
                        ) : (
                          <Typography
                            variant="body1"
                            style={{
                              marginBottom: "20px",
                              textDecoration: "underline",
                              textDecorationColor: "#01b075",
                            }}
                          >
                            No professional types available.
                          </Typography>
                        )}
                      </Grid>
                    ) : (<></>
                    )}
                    <Grid item xs={12} className="edit-schedule-input" style={{ margin: "0px 0 10px 0" }}>
                      <Typography>{t("AppointmentForm.address")}</Typography>
                      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} fullWidth>
                        <TextField label={t("AppointmentForm.searchAddress")} inputRef={inputRef} variant="outlined" style={{ width: '100%' }} className="text-field"
                          helperText={errors.location?.message} fullWidth {...register("location", { defaultValue: formdata?.location || "", })}
                          error={errors.title !== undefined ? errors.title : undefined}
                        />
                      </Autocomplete>
                    </Grid>
                    {/* Participants section: Display only when the event is not professional */}
                    {!formdata?.companyid && (
                      <Grid container item xs={12}>
                        <Typography>{t("AppointmentForm.participants")} - {formdata?.companyid}</Typography>
                        <Grid item xs={12} className="participant-selector-wrapper" >

                          {/* Search Input and Dropdown Results */}
                          <Box className="participant-search-container">
                            <TextField style={{ borderColor: '#ddd' }} className="text-field"
                              label={t("AppointmentForm.searchParticipants")}
                              onChange={handleParticipantSearch}
                              fullWidth
                              margin="normal"
                            />

                            {/* Display search results in a dropdown */}
                            {searchResults.length > 0 && (
                              <Paper className="participant-search-dropdown" elevation={3}>
                                {searchResults.map((participant) => (
                                  <ListItem
                                    key={participant.id}
                                    button
                                    onClick={() => handleParticipantSelect(participant)}
                                  >
                                    <ListItemAvatar>
                                      <Avatar>
                                        {participant.username ? participant.username.charAt(0) : "U"}
                                      </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={participant.username || "Unknown User"}
                                      secondary={participant.email}
                                    />
                                    {selectedParticipants.some((p) => p.id === participant.id) && (
                                      <CheckCircleIcon color="primary" />
                                    )}
                                  </ListItem>
                                ))}
                              </Paper>
                            )}
                          </Box>

                          {/* Selected Participants */}
                          <Box className="selected-participants"  >
                            {/* <h3>Selected Participants:</h3> */}
                            <Grid container spacing={1}>
                              {selectedParticipants.length > 0 && selectedParticipants.map((participant) => (
                                <ButtonBase style={{ padding: "5px", borderRadius: '4px!important' }}
                                  key={participant.id}
                                  onClick={(event) => handleClick(event, participant.username || "Unknown User")}
                                >
                                  <Grid item xs={12} className="participant-item">
                                    <ListItemAvatar>
                                      <Avatar>
                                        {participant.username ? participant.username.charAt(0) : "U"}
                                      </Avatar>
                                    </ListItemAvatar>
                                  </Grid>
                                </ButtonBase>
                              ))}
                            </Grid>
                            {selectedName && (
                              <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <Typography style={{ padding: '10px' }}>{selectedName}</Typography>
                              </Popover>

                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    )}


                      {formdata?.companyid && selectedServices.length > 0 &&(
                          <Grid item xs={12} container spacing={2} style={{ marginTop: '20px'}}>
                            <Typography variant="h6">{t("AppointmentForm.services")}</Typography>
                            { 
                              selectedServices.map((service) => {
                                return (
                                  <Grid item xs={12} sm={6} md={4} lg={3} key={service.id}  >
                                    <Card
                                      onClick={() => handleServiceSelect(service)}
                                      style={{
                                        marginLeft: '10px',
                                        cursor: 'pointer',
                                        minWidth: '10px',
                                        flex: '0 0 auto',
                                        border: '2px solid #4caf50',
                                        backgroundColor: '#e8f5e9',
                                      }}
                                    >
                                      <CardActionArea
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                
                                        }}
                                      >
                                        <div dangerouslySetInnerHTML={{ __html: service.icon }} />
                                        <CardContent>
                                          <Typography component="div">{t(service.name)}</Typography>
                                        </CardContent>
                                      </CardActionArea>
                                    </Card>
                                  </Grid>
                                );
                              })
                          }
                          </Grid>
                                    )}

                    <Grid xs={12} item>
                      <Typography>{t("AppointmentForm.calendar")}</Typography>
                      <Controller
                        name="calendarid"
                        control={control}
                        render={({ field }) => (
                          <Select
                            value={field.value}
                            onChange={field.onChange}
                            variant="outlined"
                            className="select-calendar-input"
                            fullWidth
                            error={errors.calendarid !== undefined ? errors.calendarid : undefined}
                          >
                            {/* helperText={errors.calendarid?.message} */}
                            {formdata?.calendars?.map((c) => (
                              <MenuItem key={c.id} value={c.id}>
                                {c.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <Typography>{t("AppointmentForm.description")}</Typography>
                    </Grid>
                    <Grid xs={12} item
                      className="description-schedule-textarea"
                      style={{
                        margin: 0,
                        position: "relative",
                        display: "flex",
                        alignItems: "flex-start",
                        margin: "10px 0 10px 0",
                        width: "100%"
                      }}
                    >
                      <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                          <TextArea
                            label={t("AppointmentForm.description")}
                            variant="outlined"
                            rows={3}
                            {...field}
                            error={errors.description !== undefined ? errors.description : ""}
                          />
                        )}
                      />
                      <SortOutlined
                        style={{
                          position: "absolute",
                          color: "var(--light-color)",
                        }}
                      />
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box>
          </Modal>
        );
  }

});

const mapStateToProps = (state) => {
  return {
    calendars: state.calendar.calendars,
    currentCalendar: state.calendar.currentCalendar,
    schedules: state.schedule.schedules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestCalendars: () => requestCalendars(dispatch),
    onReceiveCalendar: (Color, BgColor, Name, Id, selected) =>
      dispatch(receiveCalendar(Color, BgColor, Name, Id, selected)),
    onSetCalendar: (calendar) => dispatch(setCalendar(calendar)),
    onRequestSchedules: () => requestSchedules(dispatch),
    onReceiveSchedule: (
      Name,
      description,
      Recurence,
      Category,
      Location,
      calendarId,
      id,
      Start,
      End,
      RoleGroupId
    ) =>
      dispatch(
        receiveSchedule(
          Name,
          description,
          Recurence,
          Category,
          Location,
          calendarId,
          id,
          Start,
          End,
          RoleGroupId
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentForm);
