import * as signalR from "@microsoft/signalr";
import { authenticationService } from "./authentication.service";
import appConfig from "../envConfig/config";
import logger from "redux-logger";

class SignalRService {

  static instance = null;

  constructor(webSocketUrl = appConfig.hub_url) {
      if (SignalRService.instance) {
          return SignalRService.instance;
      }

     const options = {
                accessTokenFactory: () => authenticationService.currentUserToken,  
                  skipNegotiation: true,
                  logger: signalR.LogLevel.Debug,
                  logMessageContent: true,
                  //transport: signalR.HttpTransportType.LongPolling // Fallback to LongPolling
                  transport: signalR.HttpTransportType.WebSockets                
     };

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(webSocketUrl, options)
      .withAutomaticReconnect()
      .withHubProtocol(new signalR.JsonHubProtocol())
      .configureLogging(signalR.LogLevel.Information)
      .build();

      // this.connection.serverTimeoutInMilliseconds = 1000 * 60 * 5; // 5 minutes

      this.connection.keepAliveIntervalInMilliseconds = 15000; // Adjust ping interval (default: 15000ms)
      this.connection.serverTimeoutInMilliseconds = 60000; // Server timeout threshold (default: 30000ms)


      this.connection.onclose(async (error) => {
      console.error('SignalR connection closed:', error);
      await this.startConnection();
    });

    this.connection.onreconnecting((error) => {
      console.warn('SignalR connection lost. Reconnecting...', error);
    });

    this.connection.onreconnected((connectionId) => {
      console.log('SignalR reconnected. Connection ID:', connectionId);
    });

      // this.connection = new signalR.HubConnectionBuilder()
      //     .withUrl(webSocketUrl) // Replace with your actual hub URL
      //     .withAutomaticReconnect() // Automatically try to reconnect on disconnects
      //     .build();

       this.eventHandlers = {};
      SignalRService.instance = this;

      return this;
  }


  async startConnection() {
    if (this.connection.state === signalR.HubConnectionState.Connected|| this.connection.state === signalR.HubConnectionState.Connecting) {
        return; // Already connected, do nothing
    }

    try {
        await this.connection.start();
        console.log("SignalR connection established.");
    } catch (error) {
        console.error("Error establishing SignalR connection:", error);
    }
}

async stopConnection() {
  if (this.connection.state === signalR.HubConnectionState.Connected) {
      await this.connection.stop();
      console.log("SignalR connection stopped.");
  }
}

static getInstance() {
  if (!SignalRService.instance) {
      SignalRService.instance = new SignalRService();
  }
  return SignalRService.instance;
}
  // constructor(webSocketUrl = appConfig.hub_url) {
  //   this.webSocketUrl = webSocketUrl;
  //   this.connection = null;
  //   this.eventHandlers = {};
  // }

  // buildConnection() {
  //   const token = authenticationService.currentUserToken;

  //   // Check if the token is available
  //   if (!token) {
  //     console.error('No authentication token found. SignalR connection will not be established.');
  //     return null;
  //   }

  //   const options = {
  //     accessTokenFactory: () => token,  
  //       skipNegotiation: true,
  //       logger: signalR.LogLevel.Debug,
  //       logMessageContent: true,
  //       //transport: signalR.HttpTransportType.LongPolling // Fallback to LongPolling
  //       transport: signalR.HttpTransportType.WebSockets

         
  //   };

  //   const connection = new signalR.HubConnectionBuilder()
  //     .withUrl(this.webSocketUrl, options)
  //     .withAutomaticReconnect()
  //     .withHubProtocol(new signalR.JsonHubProtocol())
  //     .configureLogging(signalR.LogLevel.Information)
  //     .build();

  //   connection.serverTimeoutInMilliseconds = 1000 * 60 * 5; // 5 minutes

  //   connection.onclose(async (error) => {
  //     console.error('SignalR connection closed:', error);
  //     await this.startConnection();
  //   });

  //   connection.onreconnecting((error) => {
  //     console.warn('SignalR connection lost. Reconnecting...', error);
  //   });

  //   connection.onreconnected((connectionId) => {
  //     console.log('SignalR reconnected. Connection ID:', connectionId);
  //   });

  //   return connection;
  // }

  // async startConnection() {
    
  //   debugger;
  //   if (!this.connection) {
  //     this.connection = this.buildConnection();
  //     if (!this.connection) {
  //       return;
  //     }
  //   }

  //   try {
  //     await this.connection.start();
  //     console.log('SignalR connection established.');
  //   } catch (err) {
  //     console.error('SignalR connection error:', err);

  //     // Enhanced error handling
  //     if (err.message.includes('negotiate version')) {
  //       console.error('Negotiate version error. Ensure client and server SignalR versions are compatible.');
  //     }

  //     if (err.message.includes('WebSocket failed to connect')) {
  //       console.log('Retrying connection...');
  //       setTimeout(() => this.startConnection(), 5000); // Retry after 5 seconds
  //     } else if (err.message.includes('The connection was stopped during negotiation')) {
  //       console.error('Connection stopped during negotiation. Check server configuration and network.');
  //     } else if (err.message.includes('Unauthorized')) {
  //       console.error('Unauthorized. Check the token and user authentication.');
  //     } else {
  //       throw err;
  //     }
  //   }
  // }

  // async stopConnection() {
  //   try {
  //     if (this.connection) {
  //       await this.connection.stop();
  //       console.log('SignalR connection stopped.');
  //       this.connection = null;
  //     }
  //   } catch (err) {
  //     console.error('Error stopping SignalR connection:', err);
  //     throw err;
  //   }
  // }

  on(eventName, callback) {
    if (!this.eventHandlers[eventName]) {
      this.eventHandlers[eventName] = [];
    }

    const handler = (args) => {
      callback(args);
    };

    this.eventHandlers[eventName].push(handler);
    this.connection.on(eventName, handler);
  }

  onNotificationReceived(callback) {
    this.on("NotificationReceived", callback);
  }

  off(eventName, callback) {
    const handlers = this.eventHandlers[eventName];

    if (handlers && handlers.length > 0) {
      if (callback) {
        const index = handlers.indexOf(callback);
        if (index !== -1) {
          handlers.splice(index, 1);
          this.connection.off(eventName, callback);
        }
      } else {
        handlers.forEach((handler) => {
          this.connection.off(eventName, handler);
        });
        handlers.length = 0;
      }
    }
  }

  invoke(methodName, ...args) {
    if (this.connection) {
      return this.connection.invoke(methodName, ...args);
    } else {
      console.error('No SignalR connection established. Cannot invoke method:', methodName);
      return Promise.reject('No connection established');
    }
  }
}

export default SignalRService;





// import * as signalR from "@microsoft/signalr";
// import { authenticationService } from "./authentication.service";
// import appConfig from "../envConfig/config";

// class SignalRService {
//   constructor(webSocketUrl = appConfig.hub_url) {
//     this.connection = this.buildConnection(webSocketUrl);
//     this.eventHandlers = {};
//   }

//   buildConnection(webSocketUrl) {
//     const connection = new signalR.HubConnectionBuilder()
//         .withUrl(webSocketUrl, { accessTokenFactory: () => authenticationService.currentUserToken })
//         .withAutomaticReconnect()
//         .build();

//     // Set server timeout
//     connection.serverTimeoutInMilliseconds = 1000 * 60 * 5;

//     return connection;
// }

//   startConnection() {
//     return this.connection.start();
//   }

//   stopConnection() {
//     return this.connection.stop();
//   }

//   on(eventName, callback) {
//     if (!this.eventHandlers[eventName]) {
//       this.eventHandlers[eventName] = [];
//     }

//     const handler = (args) => {
//       // 
//       callback(args);
//     };

//     this.eventHandlers[eventName].push(handler);
//     this.connection.on(eventName, handler);
//   }

//   onNotificationReceived(callback) {
//     // 
//     this.connection.on("NotificationReceived", (notification) => {
//       callback(notification);
//     });
//   }

//   off(eventName, callback) {
//     const handlers = this.eventHandlers[eventName];

//     if (handlers && handlers.length > 0) {
//       if (callback) {
//         const index = handlers.indexOf(callback);
//         if (index !== -1) {
//           handlers.splice(index, 1);
//           this.connection.off(eventName, callback);
//         }
//       } else {
//         handlers.forEach((handler) => {
//           this.connection.off(eventName, handler);
//         });
//         handlers.length = 0;
//       }
//     }
//   }

//   invoke(methodName, ...args) {
//     return this.connection.invoke(methodName, ...args);
//   }
// }

// export default SignalRService;
