// React component to render multiple circles with Material-UI icons and text
import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

const CircleIconText = () => {
    const { t,i18n } = useTranslation();
  const items = [
    { icon: <CalendarTodayIcon style={{ fontSize: 40, color: 'white' }} />, text: 'Easy Scheduling', backgroundColor: '#85cc98' },
    { icon: <ScheduleIcon style={{ fontSize: 40, color: 'white' }} />, text: '24/7 Availability', backgroundColor: '#664de4' },
    { icon: <ThumbUpIcon style={{ fontSize: 40, color: 'white' }} />, text: 'Top Recommendations', backgroundColor: '#85cc98' },
  ];

  return (
    <Container sx={{ py: 6 }}>

            <Box
              sx={{
                textAlign: {
                  xs: "center",
                },
                textTransform: "uppercase",
                color: "#5d5dff",
              }}
              className="poppins-medium"
            >
              Simple, transparent, and flexible.
            </Box>
            <StyledH3 className="archivo-bold">{t("HomePage.dashboardWhyChooseDiarytree")}</StyledH3>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        style={{marginTop:'4%'}}
        gap={4}
      >
        {items.map((item, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                width: { xs: 80, sm: 100, md: 120 },
                height: { xs: 80, sm: 100, md: 120 },
                backgroundColor: item.backgroundColor,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: 3,
                mb: 2,
              }}
            >
              {item.icon}
            </Box>
            <Typography variant="body1" color="textPrimary" align="center">
              {item.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Container>
  );
};



const StyledH3 = styled.h3`
  font-size: 2.8rem;
  @media (max-width:900px) {
    text-align: center;
  }
  @media (max-width:1024px) {
    font-size: 2rem;
  }
`;


export default CircleIconText;
