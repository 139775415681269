import React from "react";
import "./Subscriptions.css";
import styled from "styled-components";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Box } from "@mui/material";
import CustomLink from "../../../../Components/CustomLink/CustomLink";

const Subscriptions = () => {
  return (
    <>
      <Box
        sx={{
          textAlign: {
            xs: "center",
          },
          textTransform: "uppercase",
          color: "#5d5dff",
        }}
        className="poppins-medium"
      >
        Simple, transparent, and flexible.
      </Box>
      <StyledH3 className="archivo-bold">Our Pricing Plans</StyledH3>

      <div className="subscription-packages">
        <StyledPricingCard className="subscription-package">
          <StyledPricingHeader>
            <SubscriptionLeftHeader>
              <SubscriptionTitle className="poppins-bold">
                All users
              </SubscriptionTitle>
              <SubscriptionPeriod>For life</SubscriptionPeriod>
            </SubscriptionLeftHeader>

            <span className="price">Free</span>
          </StyledPricingHeader>
          <StyledPricingBody>
            <SubscriptionDescription>
              No subscription or Credit card need. Enjoy for free all our
              functionalities to manage your event easily.
            </SubscriptionDescription>

            <SubscriptionList>
              <SubscriptionListItem className="table-feature-inner">
                <KeyboardDoubleArrowRightIcon sx={{ color: "#664de4" }} />
                <span>Synchronization with all platforms</span>
              </SubscriptionListItem>
              <SubscriptionListItem className="table-feature-inner">
                <KeyboardDoubleArrowRightIcon sx={{ color: "#664de4" }} />
                <span>Search for professionals using a map.</span>
              </SubscriptionListItem>
              <SubscriptionListItem className="table-feature-inner">
                <KeyboardDoubleArrowRightIcon sx={{ color: "#664de4" }} />
                <span>The possibility to cancel your events.</span>
              </SubscriptionListItem>
              <SubscriptionListItem className="table-feature-inner">
                <KeyboardDoubleArrowRightIcon sx={{ color: "#664de4" }} />
                <span>Receive notifications for available slots.</span>
              </SubscriptionListItem>
            </SubscriptionList>
            <CustomLink  className="nav-item nav-link rounded-button filled-button action-button">Get Started</CustomLink>
          </StyledPricingBody>
        </StyledPricingCard>
        <StyledPricingCard className="subscription-package">
          <StyledPricingHeader>
            <SubscriptionLeftHeader>
              <SubscriptionTitle className="poppins-bold">
                Pro
              </SubscriptionTitle>
              <SubscriptionPeriod>Monthly</SubscriptionPeriod>
            </SubscriptionLeftHeader>

            <span className="price">50$</span>
          </StyledPricingHeader>
          <StyledPricingBody>
            <SubscriptionDescription>
              Manage all your events in one place. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit.
            </SubscriptionDescription>

            <SubscriptionList>
              <SubscriptionListItem className="table-feature-inner">
                <KeyboardDoubleArrowRightIcon sx={{ color: "#664de4" }} />
                <span>Manage your clients times</span>
              </SubscriptionListItem>
              <SubscriptionListItem className="table-feature-inner">
                <KeyboardDoubleArrowRightIcon sx={{ color: "#664de4" }} />
                <span>Automation of the event rescheduling.</span>
              </SubscriptionListItem>
              <SubscriptionListItem className="table-feature-inner">
                <KeyboardDoubleArrowRightIcon sx={{ color: "#664de4" }} />
                <span>Manage your teams schedules and plannings.</span>
              </SubscriptionListItem>
              <SubscriptionListItem className="table-feature-inner">
                <KeyboardDoubleArrowRightIcon sx={{ color: "#664de4" }} />
                <span>Manage your clients times. </span>
              </SubscriptionListItem>
            </SubscriptionList>
            <CustomLink  className="nav-item nav-link rounded-button filled-button action-button">Get Started</CustomLink>
          </StyledPricingBody>
        </StyledPricingCard>
        <StyledPricingCard className="subscription-package">
          <StyledPricingHeader>
            <SubscriptionLeftHeader>
              <SubscriptionTitle className="poppins-bold">
                Business
              </SubscriptionTitle>
              <SubscriptionPeriod>Monthly</SubscriptionPeriod>
            </SubscriptionLeftHeader>

            <span className="price">Custom</span>
          </StyledPricingHeader>
          <StyledPricingBody>
            <SubscriptionDescription>
              Exclusives functionalities for your business. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit.
            </SubscriptionDescription>

            <SubscriptionList>
              <SubscriptionListItem className="table-feature-inner">
                <KeyboardDoubleArrowRightIcon sx={{ color: "#664de4" }} />
                <span>Integration to your systems.</span>
              </SubscriptionListItem>
              <SubscriptionListItem className="table-feature-inner">
                <KeyboardDoubleArrowRightIcon sx={{ color: "#664de4" }} />
                <span>Manage your external contacts.</span>
              </SubscriptionListItem>
              <SubscriptionListItem className="table-feature-inner">
                <KeyboardDoubleArrowRightIcon sx={{ color: "#664de4" }} />
                <span>Automation of the processes.</span>
              </SubscriptionListItem>
              <SubscriptionListItem className="table-feature-inner">
                <KeyboardDoubleArrowRightIcon sx={{ color: "#664de4" }} />
                <span></span>
              </SubscriptionListItem>
            </SubscriptionList>
            <CustomLink className="nav-item nav-link rounded-button filled-button action-button">Get Started</CustomLink>
          </StyledPricingBody>
        </StyledPricingCard>
      </div>
    </>
  );
};

const StyledPricingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
`;

const StyledPricingCard = styled.div`
  padding: 30px;
`;

const SubscriptionTitle = styled.p`
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 0;
`;

const SubscriptionPeriod = styled.p`
  font-size: 1rem;
  color: #777;
`;

const SubscriptionLeftHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledPricingBody = styled.div`
  text-align: left;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  flex: 1;
`;

const SubscriptionDescription = styled.p`
  font-size: 0.9rem;
`;

const SubscriptionList = styled.ul`
  margin-top: 30px;
  list-style: none;
  padding-left: 0;
`;

const SubscriptionListItem = styled.li`
  span {
    margin-left: 10px;
    color: #555;
  }
`;

const StyledButton = styled.button`
  background-color: ${(p) => p.theme.colors.primary};
  border-radius: 10px;
  width: 100%;
  height: 54px;
  text-transform: uppercase;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  position: relative;
  bottom: 0;
  &:hover {
    opacity: 0.9;
  }
`;

const StyledH3 = styled.h3`
  font-size: 2.8rem;
  @media (max-width:900px) {
    text-align: center;
  }
  @media (max-width:1024px) {
    font-size: 2rem;
  }
`;

export default Subscriptions;



// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import './Subscriptions.css';
// import { useTranslation } from 'react-i18next';

// const Subscriptions = () => {
//   const navigate = useNavigate();
//   const { t } = useTranslation();

//   const handleFreeClick = () => {
//     navigate('/register');
//   };

//   const handleProClick = () => {
//     navigate('/professionalcreation');
//   };

//   return (
//     <div className="subscription-packages">
//       <div className="subscription-package">
//         <span className='header'>{t('HomePage.Subscription_allUsers')}</span>
//         <span className='headerSub'>{t('HomePage.Subscription_forLife')}</span>
//         <span className='price'>{t('HomePage.Subscription_free')}</span>
//         <span className='priceSub'></span>
//         <button className='pricingbutton' onClick={handleFreeClick}>{t('HomePage.Subscription_getStarted')}</button>
//         <span className='pricingperiod'>{t('HomePage.Subscription_noSubscription')}</span>
//         <ul className='pricingoptions'>
//           <li className='table-feature-inner'>
//             <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
//               <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//             </svg>
//             <span>{t('HomePage.Subscription_syncPlatforms')}</span>
//           </li>
//           <li className='table-feature-inner'>
//             <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
//               <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//             </svg>
//             <span>{t('HomePage.Subscription_searchProfessionals')}</span>
//           </li>
//           <li className='table-feature-inner'>
//             <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
//               <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//             </svg>
//             <span>{t('HomePage.Subscription_cancelEvents')}</span>
//           </li>
//           <li className='table-feature-inner'>
//             <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
//               <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//             </svg>
//             <span>{t('HomePage.Subscription_receiveNotifications')}</span>
//           </li>
//         </ul>
//       </div>
//       <div className="subscription-package">
//         <span className='header'>{t('HomePage.Subscription_pro')}</span>
//         <span className='headerSub'>{t('HomePage.Subscription_getStarted')}</span>
//         <span className='price'></span>
//         <span className='priceSub'>/For 3 months</span>
//         <button className='pricingbutton' onClick={handleProClick}>{t('HomePage.Subscription_subscribe')}</button>
//         <span className='pricingperiod'>{t('HomePage.Subscription_manageEvents')}</span>
//         <ul className='pricingoptions'>
//           <li className='table-feature-inner'>
//             <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
//               <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//             </svg>
//             <span>{t('HomePage.Subscription_manageClientsTimes')}</span>
//           </li>
//           <li className='table-feature-inner'>
//             <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
//               <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//             </svg>
//             <span>{t('HomePage.Subscription_automationRescheduling')}</span>
//           </li>
//           <li className='table-feature-inner'>
//             <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
//               <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//             </svg>
//             <span>{t('HomePage.Subscription_manageTeamsSchedules')}</span>
//           </li>
//           <li className='table-feature-inner'>
//             <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
//               <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//             </svg>
//             <span>{t('HomePage.Subscription_manageClientsTimes')}</span>
//           </li>
//         </ul>
//       </div>
//       <div className="subscription-package">
//         <span className='header'>{t('HomePage.Subscription_pro')}</span>
//         <span className='headerSub'>{t('HomePage.Subscription_monthlyYearly')}</span>
//         <span className='price'>50$ </span>
//         <span className='priceSub'>/{t('HomePage.Subscription_monthYear')}</span>
//         <button className='pricingbutton' onClick={handleProClick}>{t('HomePage.Subscription_subscribe')}</button>
//         <span className='pricingperiod'>{t('HomePage.Subscription_manageEvents')}</span>
//         <ul className='pricingoptions'>
//           <li className='table-feature-inner'>
//             <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
//               <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//             </svg>
//             <span>{t('HomePage.Subscription_manageClientsTimes')} </span>
//           </li>
//           <li className='table-feature-inner'>
//             <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
//               <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//             </svg>
//             <span>{t('HomePage.Subscription_automationRescheduling')}</span>
//           </li>
//           <li className='table-feature-inner'>
//             <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
//               <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//             </svg>
//             <span>{t('HomePage.Subscription_manageTeamsSchedules')}</span>
//           </li>
//           <li className='table-feature-inner'>
//             <svg className="svg_tick" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
//               <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//             </svg>
//             <span>{t('HomePage.Subscription_manageClientsTimes')}</span>
//           </li>
//         </ul>
//       </div>
//       <div className="subscription-package">
//        <span className='header'>{t('HomePage.Subscription_business')}</span>
//         <span className='headerSub'>{t('HomePage.Subscription_monthlyYearly')}</span>
//         <button style={{marginTop:'110px'}} className='pricingbutton'>{t('HomePage.Subscription_contactSales')}</button>
//         <span className='price'></span>
//         <span className='priceSub'></span>
//         <span className='pricingperiod'>{t('HomePage.Subscription_exclusivesFunctionalities')}</span>
        
//         <ul  className='pricingoptions'>
//           <li className='table-feature-inner'>  <svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('HomePage.Subscription_integrationSystems')}</span></li>
//           <li className='table-feature-inner'><svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('HomePage.Subscription_manageExternalContacts')}</span></li>
//           <li className='table-feature-inner'><svg
//             className="svg_tick"
//             viewBox="0 0 12 12"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
//           </svg>
//             <span>{t('HomePage.Subscription_automationProcesses')}</span></li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Subscriptions;

