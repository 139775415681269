import React, { useState } from "react";
import "./ContactForm.css";
import { TextField, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from 'react-i18next';
import Button from "@mui/material/Button";
import styled from "styled-components";
import CustomLink from "../../../../Components/CustomLink/CustomLink";

const ContactForm = () => {

  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    debugger;
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your form submission logic here
    console.log(formData);
  };

  return (
    <StyledFormWrapper className="contact-form-container">
      <FormLeftSide className="get-in-touch">
        <div>
          <div>
            <StyledH3 className="archivo-bold">Get In Touch</StyledH3>
            <StyledSubHeading>Please fill the form to contact us</StyledSubHeading>
          </div>
          <div>
            {/* <StyledP>Your Questions, Answered</StyledP> */}
            <StyledAccordion>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                1. How can my company sign up to offer services on your
                platform?
              </StyledAccordionSummary>
              <AccordionDetails>
                <b>Answer:</b> Signing up is easy! Simply click on the "Join as a
                Company" button on our homepage. Select the pricing plan that
                best suits your business needs, fill out the registration form
                with your company's details, and submit your application. Once
                approved, you can start listing your services and reaching new
                clients.
              </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                2. How will clients find and book my services?
              </StyledAccordionSummary>
              <AccordionDetails>
                <b>Answer:</b> Clients can search for services based on type, location,
                or availability using our platform. When they find your listing,
                they can view your profile, check your availability, and book
                your service directly. You'll be notified about each booking and
                can manage your schedule seamlessly through your dashboard.
              </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                3. What are the costs for using your platform?
              </StyledAccordionSummary>
              <AccordionDetails>
                <b>Answer:</b> We offer flexible pricing plans tailored to businesses
                of all sizes. You can choose the plan that fits your needs,
                whether you're just starting out or managing a large operation.
                Pricing details are available on our Pricing page, and there are
                no hidden fees—everything is transparently listed for your
                convenience.
              </AccordionDetails>
            </StyledAccordion>
          </div>
        </div>

        <StyledContactAddress>
          Contact mail:{" "}
          <a href="mailto:contact@diarytree.com">contact@diarytree.com</a>
        </StyledContactAddress>
      </FormLeftSide>
      {/* <form className="contact-form" onSubmit={handleSubmit}> */}
      {/* <div className="form-row">
          <div className="form-group">¨ */}
      <FormRightSide>
        <Box
          component="form"
          id="contactForm" // Added form ID
          noValidate
          autoComplete="off"
          sx={{ textAlign: "left" }}
        >
          <div className="form-row">
            <div className="form-group">
          <TextField
           id="name"
           label={t('HomePage.Common_name')}
           value={formData.name}
           name="name"
           fullWidth
           onChange={handleChange}
         />
              {/* <StyledTextField
                id="name"
                fullWidth
                label={t('HomePage.Common_name')}
                value={formData.name}
                onChange={handleChange}
              /> */}
            </div>
            <div className="form-group">
              <TextField
                id="surname"
                name="surname"
                label={t('HomePage.Common_surname')}
                fullWidth
                value={formData.surname}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <TextField
                id="phoneNumber"
                label={t('HomePage.Common_phoneNumber')}
                fullWidth
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <TextField
                id="email"
                label={t('HomePage.Common_email')}
                fullWidth
                value={formData.email}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group">
            <TextField
              id="subject"
              label={t('HomePage.Common_subject')}
              fullWidth
              value={formData.subject}
              onChange={handleChange}
              placeholder="Enter your subject"
            />
          </div>
          <div className="form-group">
            <TextField
              id="message"
              fullWidth
              label={t('HomePage.Common_message')}
              multiline
              maxRows={4}
              value={formData.message}
              onChange={handleChange}
              rows="6"
              required
            />
          </div>
          
          <CustomLink formId="contactForm"  isSubmit={true}  className="nav-item nav-link rounded-button filled-button action-button">Send</CustomLink>
        </Box>
      </FormRightSide>
    </StyledFormWrapper>
  );
};

const StyledFormWrapper = styled.div`
  display: flex;
  flex: 1;
  max-width: 1200px;
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 900px) {
    flex-direction: column;
    padding-top: 0;
  }
`;

const FormLeftSide = styled.div`
  text-align: left;
  display: flex;
  padding-right: 40px;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 900px) {
      text-align: center;
      padding-right: 0;
  }
`;

const FormRightSide = styled.div`
  flex: 1;
`;

const StyledTextField = styled(TextField)`
  & .MuiInputLabel-root {
    font-weight: 400;
  }
`;

const StyledH3 = styled.h3`
  font-size: 2.8rem;
  @media (max-width:900px) {
    text-align: center;
    padding-bottom: 20px;
  }
  @media (max-width:1024px) {
    font-size: 2rem;
  }
`;

const StyledContactAddress = styled.p`
  font-size: 0.8rem !important;
`;

const StyledButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary} !important;
  width: 50%;
  border-radius: 5px;
  height: 54px;
  font-size: 1.2rem;
  font-weight: 500;
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: none !important;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

// const StyledP = styled.p`
//   color: ${(p) => p.theme.colors.primary} !important;
//   font-weight: 700 !important;
//   margin-top: 30px;
// `;

const StyledSubHeading = styled.p`
  font-size: 1rem;
  @media (max-width: 900px) {
      text-align: center;
  }
`;

export default ContactForm;



// import React, { useState } from 'react';
// import './ContactForm.css';
// import { Box, TextField, Button, Grid } from '@mui/material';
// import {authenticationService} from "../../../../app/services"
// import { useTranslation } from 'react-i18next';

// // import { Box } from 'react-bootstrap-icons';

// const ContactForm = () => {
//   const { t } = useTranslation();
//   const [formData, setFormData] = useState({
//     name: '',
//     surname: '',
//     phoneNumber: '',
//     email: '',
//     subject: '',
//     message: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     authenticationService
//       .contactUs(formData)
//       .then(
//         (user) => {
//           alert(user);
//         },
//         (error) => {
//           alert(error)
//         }
//       );
//   };

//   return (

//     <div className="contact-form-container">
//   <div className="get-in-touch">
//     <h2>{t('HomePage.Footer_contact')}</h2>
//     <p>{t('HomePage.Footer_contactFillForm')}</p>
//     <p>Contact mail: <a href="mailto:contact@diarytree.com">contact@diarytree.com</a></p>
//   </div>

//   <Box
//     component="form"
//     sx={{
//       '& .MuiTextField-root': { m: 1 },
//       display: 'flex',
//       flexDirection: 'column',
//       gap: '16px',
//     }}
//     noValidate
//     autoComplete="off"
//   >
//     <Grid container spacing={2}>
//       <Grid item xs={12} md={6}>
//         <TextField
//           id="name"
//           label={t('HomePage.Common_name')}
//           value={formData.name}
//           name="name"
//           fullWidth
//           onChange={handleChange}
//         />
//       </Grid>

//       <Grid item xs={12} md={6}>
//         <TextField
//           id="surname"
//           label={t('HomePage.Common_surname')}
//           value={formData.surname}
//           name="surname"
//           fullWidth
//           onChange={handleChange}
//         />
//       </Grid>

//       <Grid item xs={12} md={6}>
//         <TextField
//           id="phoneNumber"
//           label={t('HomePage.Common_phoneNumber')}
//           name="phoneNumber"
//           value={formData.phoneNumber}
//           fullWidth
//           onChange={handleChange}
//         />
//       </Grid>

//       <Grid item xs={12} md={6}>
//         <TextField
//           id="email"
//           label={t('HomePage.Common_email')}
//           value={formData.email}
//           onChange={handleChange}
//           name="email"
//           required
//           fullWidth
//         />
//       </Grid>

//       <Grid item xs={12}>
//         <TextField
//           id="subject"
//           label={t('HomePage.Common_subject')}
//           value={formData.subject}
//           name="subject"
//           onChange={handleChange}
//           fullWidth
//           required
//         />
//       </Grid>

//       <Grid item xs={12}>
//         <TextField
//           id="message"
//           label={t('HomePage.Common_message')}
//           name="message"
//           multiline
//           maxRows={4}
//           rows="6"
//           value={formData.message}
//           onChange={handleChange}
//           fullWidth
//           required
//         />
//       </Grid>

//       <Grid item xs={12}>
//         <Button
//           className="formbutton"
//           variant="contained"
//           color="primary"
//           onClick={handleSubmit}
//           fullWidth
//         >
//           Send
//         </Button>
//       </Grid>
//     </Grid>
//   </Box>
// </div>

//     // <div className="contact-form-container">
//     //   <div className="get-in-touch">
//     //     <h2>{t('HomePage.Footer_contact')}</h2>
//     //     <p>{t('HomePage.Footer_contactFillForm')}</p>
//     //     <p>Contact mail: <a href="mailto:contact@diarytree.com">contact@diarytree.com</a></p>
//     //   </div>
//     //       <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' },}}  noValidate autoComplete="off">   
//     // <div className="form-row">
//     //     <div className="form-group">
//     //       <TextField id="name" label={t('HomePage.Common_name')} 
//     //           value={formData.name}
//     //           name="name"
//     //           onChange={handleChange}/>
//     //       </div>
//     //       <div className="form-group">
            
//     //       <TextField id="surname" label={t('HomePage.Common_surname')}   
//     //           value={formData.surname}
//     //           name="surname"
//     //           onChange={handleChange}/>
//     //       </div>
//     //     </div>
//     //     <div className="form-row">
//     //       <div className="form-group">
//     //         <TextField id="phoneNumber" label={t('HomePage.Common_phoneNumber')} 
//     //           name="phoneNumber" 
//     //           value={formData.phoneNumber}
//     //           onChange={handleChange}/>
//     //       </div>
//     //       <div className="form-group">
                      
//     //       <TextField id="email" label={t('HomePage.Common_email')}  
//     //           value={formData.email}
//     //           onChange={handleChange} 
//     //           name="email" required/>
//     //       </div>
//     //     </div>
//     //     <div className="form-group">
          
//     //     <TextField id="subject" label={t('HomePage.Common_subject')}   style={{width:'100%'}}
//     //          name="subject"
//     //           value={formData.subject}
//     //           onChange={handleChange}  required/>
//     //     </div>
//     //     <div className="form-group">
//     //     <TextField
//     //       id="message"  style={{width:'100%'}}
//     //       label={t('HomePage.Common_message')}
//     //         name="message"
//     //       multiline
//     //       maxRows={4}   value={formData.message}
//     //       onChange={handleChange}
//     //       rows="6"
//     //       required
//     //     />
//     //     </div>
//     //     <Button className="formbutton" variant="contained" color="primary" onClick={handleSubmit}>
//     //     Send
//     //   </Button>
//     //     </Box>
//     //  </div>
//   );
// };

// export default ContactForm;
